import { httpService } from 'services/HttpServices';
import { accessToken } from 'services/AuthService';

const getMethod = 'get';
const postMethod = 'post';

class EventHttpServices {
	async GetAuthorizationHeaders(slug) {
		const token = await accessToken(slug);
		const authToken = `Bearer ${token}`;
		return {
			Authorization: authToken,
		};
	}

	async get(path, payload = null, slug = null) {
		const headers = await this.GetAuthorizationHeaders(slug);
		return httpService.request(getMethod, path, payload, headers);
	}

	async post(path, slug, payload = null) {
		const headers = await this.GetAuthorizationHeaders(slug);
		return httpService.request(postMethod, path, payload, headers);
	}
}
const EventHttpService = new EventHttpServices();

export default EventHttpService;
