export const getSession = (state) => state.session;

export const getBackupUrl = (state, id) => {
	const video = getSession(state);
	const sessions = video.sessions;

	if (!video || !sessions) return '';

	for (let i = 0; i < sessions.length; i += 1) {
		if (parseInt(id) === sessions[i].id) {
			return sessions[i].backupVideoUrl;
		}
	}

	return '';
};

export const getQuestionActive = (state, id) => {
	const video = getSession(state);
	const sessions = video.sessions;

	if (!video || !sessions) return '';

	for (let i = 0; i < sessions.length; i += 1) {
		if (parseInt(id) === sessions[i].id) {
			return sessions[i].questionActive;
		}
	}

	return false;
};

export const getMyQuestions = (state, id) => {
	const video = getSession(state);
	const sessions = video.sessions;

	if (!video || !sessions) return '';

	for (let i = 0; i < sessions.length; i += 1) {
		if (parseInt(id) === sessions[i].id) {
			return sessions[i].questions;
		}
	}

	return [];
};

export const getEventId = (state, id) => {
	const video = getSession(state);
	const sessions = video.sessions;

	if (!video || !sessions) return 0;

	for (let i = 0; i < sessions.length; i += 1) {
		if (parseInt(id) === sessions[i].id) {
			return sessions[i].eventId;
		}
	}

	return 0;
};

export const getSessionInformation = (state, id) => {
	const video = getSession(state);
	const sessions = video.sessions;

	if (!video || !sessions) return '';

	for (let i = 0; i < sessions.length; i += 1) {
		if (parseInt(id) === sessions[i].id) {
			return sessions[i].sessionInformation;
		}
	}

	return '';
};

export const getAgendaInformation = (state, id) => {
	const video = getSession(state);
	const sessions = video.sessions;

	if (!video || !sessions) return '';

	for (let i = 0; i < sessions.length; i += 1) {
		if (parseInt(id) === sessions[i].id) {
			return sessions[i].agenda;
		}
	}

	return '';
};

export const getShowSideBarToggler = (state, id) => {
	const video = getSession(state);
	const sessions = video.sessions;

	if (!video || !sessions) return true;

	for (let i = 0; i < sessions.length; i += 1) {
		if (parseInt(id) === sessions[i].id) {
			return sessions[i].sidebarSwitch;
		}
	}

	return true;
};

export const getVideoToggler = (state, id) => {
	const video = getSession(state);
	const sessions = video.sessions;

	if (!video || !sessions) return true;

	for (let i = 0; i < sessions.length; i += 1) {
		if (parseInt(id) === sessions[i].id) {
			return sessions[i].videoSwitch;
		}
	}

	return true;
};
