import {
	UPDATE_CURRENT_VIDEO,
	UPDATE_ASSET_VIDEO,
	MY_QUESTIONS,
	UPDATE_QUESTION_STATE,
	TOGGLE_SIDEBAR,
	TOGGLE_VIDEO,
} from 'actions/types';

const defaultVideoState = {
	sessions: [],
};

export const session = (state = defaultVideoState, action) => {
	switch (action.type) {
		case UPDATE_CURRENT_VIDEO: {
			const {
				id,
				videoUrl,
				backupVideoUrl,
				question_active,
				eventId,
				session_information,
				agenda,
				default_state_sidebar_toggler,
				default_state_video_toggler,
			} = action.data;
			for (let i = 0; i < state.sessions.length; i += 1) {
				if (id === state.sessions[i].id) {
					state.sessions[i].videoUrl = videoUrl;
					state.sessions[i].backupVideoUrl = backupVideoUrl;
					state.sessions[i].questionActive = question_active;
					state.sessions[i].eventId = eventId;
					state.sessions[i].sessionInformation = session_information;
					state.sessions[i].agenda = agenda;
					return {
						sessions: [...state.sessions],
					};
				}
			}
			return {
				sessions: [
					...state.sessions,
					{
						id: id,
						videoUrl: videoUrl,
						backupVideoUrl: backupVideoUrl,
						questionActive: question_active,
						eventId: eventId,
						sessionInformation: session_information,
						sidebarSwitch: default_state_sidebar_toggler,
						videoSwitch: default_state_video_toggler,
						agenda: agenda,
					},
				],
			};
		}
		case UPDATE_ASSET_VIDEO: {
			const { id, videoUrl, backupVideoUrl, questionActive, eventId } =
				action.data;
			for (let i = 0; i < state.sessions.length; i += 1) {
				if (id === state.sessions[i].id) {
					state.sessions[i].videoUrl = videoUrl;
					state.sessions[i].backupVideoUrl = backupVideoUrl;
					state.sessions[i].questionActive = questionActive;
					state.sessions[i].eventId = eventId;
					return {
						sessions: [...state.sessions],
					};
				}
			}
			return state;
		}
		case MY_QUESTIONS: {
			const { questions, videoId } = action;
			let myQuestions = questions.filter(function (question) {
				return question.myQuestion == true;
			});
			for (let i = 0; i < state.sessions.length; i += 1) {
				if (parseInt(videoId) === state.sessions[i].id) {
					state.sessions[i].questions = myQuestions;
					return {
						sessions: [...state.sessions],
					};
				}
			}

			return state;
		}
		case UPDATE_QUESTION_STATE: {
			const { videoId, question } = action;
			for (let i = 0; i < state.sessions.length; i += 1) {
				if (parseInt(videoId) === state.sessions[i].id) {
					if (state.sessions[i].questions) {
						state.sessions[i].questions = [
							question,
							...state.sessions[i].questions,
						];
					} else {
						state.sessions[i].questions = [question];
					}

					return {
						sessions: [...state.sessions],
					};
				}
			}
			return state;
		}
		case TOGGLE_SIDEBAR: {
			const { id } = action;
			for (let i = 0; i < state.sessions.length; i++) {
				if (parseInt(id) === state.sessions[i].id) {
					state.sessions[i].sidebarSwitch = !state.sessions[i].sidebarSwitch;
					return {
						sessions: [...state.sessions],
					};
				}
			}
			return state;
		}
		case TOGGLE_VIDEO: {
			const { id, value } = action;
			for (let i = 0; i < state.sessions.length; i++) {
				if (parseInt(id) === state.sessions[i].id) {
					state.sessions[i].videoSwitch = value;
					return {
						sessions: [...state.sessions],
					};
				}
			}
			return state;
		}
		default:
			return state;
	}
};
