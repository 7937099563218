export const getEvent = (state) => state.event;

export const getCurrentEventSlug = (state) => {
	const event = getEvent(state);
	let { currentEventSlug } = event;
	if (!event || !currentEventSlug) return '';

	return currentEventSlug;
};

export const getContentBlock = (state) => {
	const event = getEvent(state);
	let {
		header_img,
		registration,
		has_registration,
		registration_slug,
		footer_text,
		welcome_text,
		sign_in_text,
		otp_receive_page,
		otp_input_page,
		otp_receive_page_footer,
		agenda_header,
		above_my_session,
		below_my_session,
		navigation_background_color,
		navigation_text_color,
		navigation_font_size,
		navigation_active_color,
		date_time_format,
	} = event;

	if (!event) return '';

	return {
		header_img,
		registration,
		has_registration,
		registration_slug,
		footer_text,
		welcome_text,
		sign_in_text,
		otp_receive_page,
		otp_input_page,
		otp_receive_page_footer,
		agenda_header,
		above_my_session,
		below_my_session,
		navigation_background_color,
		navigation_text_color,
		navigation_font_size,
		navigation_active_color,
		date_time_format,
	};
};
