export const getEnv = () => {
	return process.env.REACT_APP_ENV || 'local';
};

export const getEndPoint = () => {
	return process.env.REACT_APP_API_URL;
};

export const getApiToken = () => {
	return process.env.REACT_APP_API_TOKEN || '';
};

export const isProduction = () => {
	const env = getEnv().toLowerCase();
	return env === 'prod' || env === 'production';
};

export const getImageBasePath = (fileName) => {
	return getEndPoint() + fileName;
};

export const getVideoTrackURL = () => {
	return process.env.REACT_APP_VIDEO_TRACK_URL;
};

export const getDisableEmail = () => {
	return process.env.REACT_APP_DISABLE_EMAIL === 'true';
};

export const getTheoURL = () => {
	return process.env.REACT_APP_THEO_URL || '';
};

export const getPusherKey = () => {
	return process.env.REACT_APP_PUSHER_KEY;
};

export const getPusherCluster = () => {
	return process.env.REACT_APP_PUSHER_CLUSTER;
};

export const getPusherChannel = () => {
	return process.env.REACT_APP_PUSHER_CHANNEL_NAME;
};
