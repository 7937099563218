import * as allTypes from './types';

export function eventSlug(slug, callbackSuccess, callbackError) {
	return {
		type: allTypes.EVENT_SLUG_INFO,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function allEvents(callbackSuccess, callbackError) {
	return {
		type: allTypes.ALL_EVENTS,
		callbackSuccess,
		callbackError,
	};
}

export function eventInformation(slug, callbackSuccess, callbackError) {
	return {
		type: allTypes.EVENT_INFORMATION,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function eventRedirectUpdate(data) {
	return {
		type: allTypes.UPDATE_EVENT_REDIRECT,
		data,
	};
}

export function updateEventSlug(slug) {
	return {
		type: allTypes.UPDATE_EVENT_SLUG,
		slug,
	};
}

export function eventRegistration(slug, callbackSuccess, callbackError) {
	return {
		type: allTypes.EVENT_REGISTRATION,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function submitEventRegistration(
	slug,
	submitForm,
	selectedSessions,
	submitSuccess,
	submitError
) {
	return {
		type: allTypes.SUBMIT_EVENT_REGISTRATION,
		slug,
		submitForm,
		selectedSessions,
		submitSuccess,
		submitError,
	};
}

export function firstEvent(callbackSuccess) {
	return {
		type: allTypes.FIRST_EVENT,
		callbackSuccess,
	};
}

export function getVideoPlayer(id, slug, callbackSuccess, callbackError) {
	return {
		type: allTypes.GET_VIDEO_PLAYER,
		id,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function getContentBlock(slug, callbackSuccess, callbackError) {
	return {
		type: allTypes.GET_CONTENT_BLOCK,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function setContentData(data) {
	return {
		type: allTypes.SET_CONTENT_DATA,
		data,
	};
}

export function clearContentData() {
	return {
		type: allTypes.CLEAR_CONTENT_DATA,
	};
}
