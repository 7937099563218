import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams, withRouter } from 'react-router-dom';
import { isLoggedIn } from 'selectors/auth';
import { AccessTokenLogin } from 'actions/auth';
import PATHS from 'routes';

const ATLogin = (props) => {
	let { slug, token } = useParams();

	if (props.isLoggedIn(slug)) {
		props.history.push(`/${slug}/info`);
	}

	const handleSuccess = () => console.log('done');
	const handleError = () => {
		toast.error('Invalid Link', {
			position: 'bottom-right',
			autoClose: true,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		props.history.push(PATHS.HOME);
	};

	useEffect(() => {
		const { AccessTokenLogin } = props;
		AccessTokenLogin(slug, token, handleSuccess, handleError);
	}, [token]);
	return <></>;
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	AccessTokenLogin,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(ATLogin));
