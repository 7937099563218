import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from 'react-bootstrap/Table';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import SideAccordion from './SideAccordion';

const Agenda = () => {
	return (
		<div className="agenda_list">
			<Table bordered size="sm">
				<thead>
					<tr>
						<th>Start</th>
						<th>Session</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>10:30am</td>
						<td>Acknowledgement of Country - Mundanara Bayles (BlackCard)</td>
					</tr>
					<tr>
						<td>10:36am</td>
						<td>Welcome</td>
					</tr>
					<tr>
						<td>10:44am</td>
						<td>CEO presentation</td>
					</tr>
					<tr className="break">
						<td>11:15am</td>
						<td>Morning break (10 mins)</td>
					</tr>
					<tr>
						<td>11:25am</td>
						<td>Member interview – Julie</td>
					</tr>
					<tr>
						<td>11:44am</td>
						<td>Keynote address - Jennie McLaughlin (Ernst {'&'} Young)</td>
					</tr>
					<tr>
						<td>12:13pm</td>
						<td>
							Keynote Q{'&'}A - Debby, Stephen, Sonya {'&'} Lisa
						</td>
					</tr>
					<tr>
						<td>12:43pm</td>
						<td>Review of morning</td>
					</tr>
					<tr className="break">
						<td>12:45pm</td>
						<td>Lunch break (30 mins)</td>
					</tr>
					<tr>
						<td>1:15pm</td>
						<td>Q{'&'}A with Executive Team</td>
					</tr>
					<tr>
						<td>2:05pm</td>
						<td>10yr service award</td>
					</tr>
					<tr className="break">
						<td>2:12pm</td>
						<td>Afternoon break (10 mins)</td>
					</tr>
					<tr>
						<td>2:22pm</td>
						<td>Member interview – Ben</td>
					</tr>
					<tr>
						<td>2:46pm</td>
						<td>Values in Action activity</td>
					</tr>
					<tr>
						<td>3:12pm</td>
						<td>Conference wrap up</td>
					</tr>
					<tr>
						<td>3:15pm</td>
						<td>Conference celebration - quiz</td>
					</tr>
				</tbody>
			</Table>
		</div>
	);
};

const AgendaInformation = (props) => {
	const { label } = props;

	return (
		<div className="mb-4">
			<SideAccordion
				icon={<FontAwesomeIcon icon={faCalendar} />}
				title={label}
				component={<Agenda />}
			/>
		</div>
	);
};

export default AgendaInformation;
