const PATHS = {
	HOME: '/',
	EVENT_HOME: '/:slug',
	EVENT: '/:slug/login',
	EVENT_INFO: '/:slug/info',
	GET_OTP: '/:slug/get-otp',
	AGENDA: '/:slug/agenda',
	OTP: '/:slug/verify-otp',
	WATCH: '/:slug/watch-now/:id',
	TOKEN_LOGIN: '/:slug/token=:token_login',
	SPECIAL_URL_TOKEN: '/event/:slug/special-token-url=:url_token',
	EVENT_REGISTRATION: '/:event_slug/register/:slug',
	RESET_PASSWORD: '/event/:slug/forget-password',
	AT_LOGIN: '/:slug/login/access_token=:token',
	SYS_REQ: '/system/requirements',
	EDIT_MY_SESSIONS: '/event/:slug/sessions',
	NOT_FOUND: '*',
};

export default PATHS;
