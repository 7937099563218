import React, { useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';

const AlertSignal = (props) => {
	const { data, close, className } = props;
	const [state, setState] = useState(true);
	useEffect(() => {
		if (data.alertPersistence && data.alertPersistence > 0) {
			setTimeout(() => {
				setState(false);
				close(data.id);
			}, data.alertPersistence * 1000);
		}
	}, [data]);

	return (
		<>
			{state && (
				<Alert
					variant={data.designStyle}
					onClose={() => close(data.id)}
					dismissible
					className={`${className ? className : ''}`}
				>
					<div
						className="break-all"
						dangerouslySetInnerHTML={{
							__html: data.informationText,
						}}
					></div>
				</Alert>
			)}
		</>
	);
};

export default AlertSignal;
