import { httpService } from 'services/HttpServices';
import { getEndPoint } from 'config/Config';

export const emailPassword = (email, password, slug, recaptchaValue) => {
	const completePath = getEndPoint() + '/email-password-login/';
	const payload = {
		email: email,
		password: password,
		slug,
		recaptcha: recaptchaValue,
	};
	return httpService.request('post', completePath, payload);
};

export const singleEventPassword = (email, password, slug, recaptchaValue) => {
	const completePath = getEndPoint() + '/single-event-login/';
	const payload = {
		email: email,
		slug: slug,
		password: password,
		recaptcha: recaptchaValue,
	};
	return httpService.request('post', completePath, payload);
};

export const whitelistLogin = (email, slug, recaptchaValue) => {
	const completePath = getEndPoint() + '/whitelist-login/';
	const payload = { email: email, slug: slug, recaptcha: recaptchaValue };
	return httpService.request('post', completePath, payload);
};

export const otpVerifyEmail = (email, slug, recaptchaValue) => {
	const completePath = getEndPoint() + '/check-email/';
	const payload = { email: email, slug: slug, recaptcha: recaptchaValue };
	return httpService.request('post', completePath, payload);
};

export const requestOTP = (email = null, phone = null, slug) => {
	const completePath = getEndPoint() + '/login/';
	const payload = { email, phone: phone ? phone : '', slug: slug };
	return httpService.request('post', completePath, payload);
};

export const verifyOtp = (email, phone, otp, slug) => {
	const completePath = getEndPoint() + '/verify-otp/';
	const payload = { email, phone, otp, slug };
	return httpService.request('post', completePath, payload);
};

export const logout = (accessToken, refreshToken) => {
	const completePath = getEndPoint() + '/logout/';
	const payload = { accessToken, refreshToken };
	return httpService.request('post', completePath, payload);
};

export const refreshAccessToken = (refreshToken, email, slug) => {
	const completePath = getEndPoint() + '/token/refresh/';
	const payload = { refresh: refreshToken, email: email, slug: slug };
	return httpService.request('post', completePath, payload);
};

export const tokenLogin = (token, slug) => {
	const completePath = getEndPoint() + '/token-based-login/';
	const payload = { slug: slug, token: token };
	return httpService.request('post', completePath, payload);
};

export const resetPassword = (email, slug) => {
	const completePath = getEndPoint() + '/forget-password';
	const payload = { slug: slug, email: email };
	return httpService.request('post', completePath, payload);
};

export const accessTokenLogin = (token) => {
	const completePath = getEndPoint() + '/login-with-access-token/';
	const payload = { access_token: token };
	return httpService.request('post', completePath, payload);
};

export const whitelistAndSinglePassword = (
	email,
	password,
	slug,
	recaptchaValue
) => {
	const completePath = getEndPoint() + '/whitelist-single-password/';
	const payload = {
		email: email,
		password: password,
		slug: slug,
		recaptcha: recaptchaValue,
	};
	return httpService.request('post', completePath, payload);
};

export const emailOnly = (email, slug, recaptchaValue) => {
	const completePath = getEndPoint() + '/email-only-login/';
	const payload = { email: email, slug: slug, recaptcha: recaptchaValue };
	return httpService.request('post', completePath, payload);
};

export const checkManager = (slug, url_token) => {
	const completePath = getEndPoint() + '/special-url-token/';
	const payload = { slug, url_token };

	return httpService.request('post', completePath, payload);
};
