import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SideAccordion from './SideAccordion';

const Breakout = () => {
	return (
		<>
			<p>
				To join the breakout room, please{' '}
				<a
					href="https://hesta.zoom.us/j/97900020394?pwd=VVRxTXhKNW55Y0toaFhtOVc3ODhrZz09)"
					target="_blank"
					rel="noreferrer"
				>
					click here
				</a>
			</p>
			<p>Meeting ID: 979 0002 0394</p>
			<p>Passcode: 284073</p>
		</>
	);
};

const BreakoutRoom = () => {
	return (
		<div className="mb-4">
			<SideAccordion
				icon={<FontAwesomeIcon icon={faInfoCircle} />}
				title="Values in Action"
				component={<Breakout />}
			/>
		</div>
	);
};

export default BreakoutRoom;
