import * as formFields from 'constants/Fields';

export const ValidateEmail = (email) => {
	const emailRegExp = /^\S+@\w+([.-]?\w+)*(\.\w{2,15})+$/;

	if (!email) {
		return false;
	}
	email = email.trim();

	return emailRegExp.test(email);
};

export const ValidatePhone = (phone) => {
	const validStartWith = ['+61', '0'];
	let flag = false;

	if (!phone) {
		return false;
	}
	if (phone.length < 9) {
		return false;
	}
	for (let i = 0; i < validStartWith.length; i++) {
		if (phone.startsWith(validStartWith[i])) {
			flag = true;
		}
	}
	return flag;
};

export const validateFormWithoutSession = (requiredForm, submittedForm) => {
	let error = [];
	let errorRecorded = [];
	for (let i = 0; i < requiredForm.length; i++) {
		if (requiredForm[i].field_type === formFields.EMAIL) {
			if (
				requiredForm[i].required &&
				submittedForm[requiredForm[i].id] === ''
			) {
				if (errorRecorded.indexOf(requiredForm[i].id) < 0) {
					error.push({
						id: requiredForm[i].id,
						error: 'Email is required.',
					});
					errorRecorded.push(requiredForm[i].id);
				}
			} else if (submittedForm[requiredForm[i].id] !== '') {
				if (!ValidateEmail(submittedForm[requiredForm[i].id])) {
					if (errorRecorded.indexOf(requiredForm[i].id) < 0) {
						error.push({
							id: requiredForm[i].id,
							error: 'Email is not valid.',
						});
						errorRecorded.push(requiredForm[i].id);
					}
				}
			}
		}
	}

	return error;
};
