export const getAccount = (state) => state.account;

export const getUserEmail = (state) => {
	const account = getAccount(state);

	if (!account) return '';

	return account.email;
};

export const getUserInfo = (state) => {
	const account = getAccount(state);

	if (!account) return '';

	return {
		userId: account.userId,
		tenantId: account.tenantId,
	};
};

export const getUserFullName = (state) => {
	const account = getAccount(state);

	if (!account) return '';

	if (account.firstName && account.lastName) {
		return `${account.firstName} ${account.lastName}`;
	} else if (account.email) {
		return account.email;
	}

	return '';
};

export const getSchemaName = (state) => {
	const account = getAccount(state);
	if (!account) return '';

	if (account.__schema) {
		return account.__schema;
	} else if (account.tenantId) {
		return account.tenantId;
	}

	return '';
};
