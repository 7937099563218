import * as allTypes from './types';

export function emailAndPassword(
	email,
	password,
	slug,
	recaptchaValue,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.EMAIL_PASSWORD_LOGIN,
		email,
		password,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	};
}

export function singleEventPassword(
	email,
	password,
	slug,
	recaptchaValue,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.SINGLE_EVENT_PASSWORD,
		email,
		password,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	};
}

export function whitelist(
	email,
	slug,
	recaptchaValue,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.WHITELIST_LOGIN,
		email,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	};
}

export function otpVerifyEmail(
	email,
	slug,
	recaptchaValue,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.OTP_VERIFY_EMAIL,
		email,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	};
}

export function requestOTP(
	email = null,
	phone = null,
	slug,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.REQUEST_OTP,
		email,
		phone,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function verifyOTP(
	email = null,
	phone = null,
	otp,
	slug,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.VERIFY_OTP,
		email,
		phone,
		otp,
		slug,
		callbackSuccess,
		callbackError,
	};
}

// Reducers
export function authInfoUpdate(userId, slug, accessToken, refreshToken) {
	return {
		type: allTypes.AUTH_INFO_UPDATE,
		userId,
		slug,
		accessToken,
		refreshToken,
	};
}

export function logout(callbackSuccess = null, callbackError = null) {
	return {
		type: allTypes.LOGOUT,
		callbackSuccess,
		callbackError,
	};
}

// Reducers
export function authLogoutControl(slug) {
	return {
		type: allTypes.AUTH_LOGOUT_CONTROL,
		slug,
	};
}

export function accountLogoutControl(slug) {
	return {
		type: allTypes.ACCOUNT_LOGOUT_CONTROL,
		slug,
	};
}

export function refreshInfoUpdate(access, refresh, slug) {
	return {
		type: allTypes.REFRESH_INFO_UPDATE,
		access,
		refresh,
		slug,
	};
}

export function tokenLogin(token, slug, callbackSuccess, callbackError) {
	return {
		type: allTypes.TOKEN_LOGIN,
		token,
		slug,
		callbackSuccess,
		callbackError,
	};
}

export function authLogoutRequest(slug, logoutSuccess = '', logoutError = '') {
	return {
		type: allTypes.AUTH_LOGOUT_REQUEST,
		slug,
		logoutSuccess,
		logoutError,
	};
}

export function requestPasswordChange(email, slug, handleSuccess, handleError) {
	return {
		type: allTypes.RESET_PASSWORD,
		email,
		slug,
		handleSuccess,
		handleError,
	};
}

export function AccessTokenLogin(slug, token, handleSuccess, handleError) {
	return {
		type: allTypes.ACCESS_TOKEN_LOGIN,
		slug,
		token,
		handleSuccess,
		handleError,
	};
}

export function whitelistAndSingleEvent(
	email,
	password,
	slug,
	recaptchaValue,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.WHITELIST_SINGLE_PASSWORD,
		email,
		password,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	};
}

export function emailOnly(
	email,
	slug,
	recaptchaValue,
	callbackSuccess,
	callbackError
) {
	return {
		type: allTypes.EMAIL_ONLY,
		email,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	};
}

export function authCheckManagerToken(
	slug,
	url_token,
	callbackSuccess = null,
	callbackError = null
) {
	return {
		type: allTypes.MANAGER_TOKEN,
		slug,
		url_token,
		callbackSuccess,
		callbackError,
	};
}
