import * as allTypes from 'actions/types';

const defaultAccountState = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	tenantId: '',
	userId: '',
	__schema: '',
};

export const account = (state = defaultAccountState, action) => {
	switch (action.type) {
		case allTypes.ACCOUNT_UPDATE: {
			const { userId, email, firstName, lastName, tenantId, __schema } = action;
			return {
				...state,
				userId,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			};
		}
		case allTypes.ACCOUNT_LOGOUT_CONTROL: {
			return {
				...state,
				firstName: '',
				lastName: '',
				email: '',
				phone: '',
				tenantId: '',
				userId: '',
				__schema: '',
			};
		}
		default:
			return state;
	}
};
