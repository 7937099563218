import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const SideAccordion = ({
	icon,
	title,
	component,
	openPoll = false,
	newClass = '',
}) => {
	let myref = useRef();
	let otherref = useRef();
	let acc = useRef();
	if (openPoll) {
		if (acc.current.state.status !== 'entered') myref.current.click();
	}
	return (
		<Accordion className="personal-card">
			<Card>
				<Accordion.Toggle
					as={Card.Header}
					className="flex cursor"
					eventKey="0"
					ref={newClass === 'poll' ? myref : otherref}
				>
					<div className="pull-left">{icon}</div>
					<div>{title}</div>
					<div>
						<FontAwesomeIcon icon={faChevronDown} className="pull-right" />
					</div>
				</Accordion.Toggle>
				<Accordion.Collapse
					className={`${newClass === 'poll' ? 'poll' : ''}`}
					eventKey="0"
					ref={acc}
				>
					<Card.Body>{component}</Card.Body>
				</Accordion.Collapse>
			</Card>
		</Accordion>
	);
};

export default SideAccordion;
