import * as allTypes from './types';

// Reducers
export function accountInfoUpdate(
	email,
	userId,
	firstName,
	lastName,
	tenantId,
	__schema
) {
	return {
		type: allTypes.ACCOUNT_UPDATE,
		email,
		userId,
		firstName,
		lastName,
		tenantId,
		__schema,
	};
}
