import {
	ERROR_EMPTY_EMAIL,
	ERROR_EMPTY_PASSWORD,
	ERROR_EMAIL,
	ERROR_EMPTY_PHONE,
	ERROR_PHONE,
	ERROR_EMPTY_OTP,
	ERROR_FIELD_EMPTY,
	ERROR_EMPTY_FIRST_NAME,
	ERROR_EMPTY_SURNAME,
	ERROR_ACK_REQUIRED,
} from 'constants/ErrorMessages';
import { ValidateEmail, ValidatePhone } from 'util/Validator';

export const emailPasswordValidation = (email, password) => {
	const err = {};
	if (!email) err.email = ERROR_EMPTY_EMAIL;
	if (!password) err.password = ERROR_EMPTY_PASSWORD;
	if (!ValidateEmail(email) && !err.email) err.email = ERROR_EMAIL;
	return err;
};

export const singlePasswordValidation = (email, password) => {
	const err = {};
	if (!password) err.password = ERROR_EMPTY_PASSWORD;
	if (!ValidateEmail(email) && !err.email) err.email = ERROR_EMAIL;
	return err;
};

export const whitelistValidation = (email) => {
	const err = {};
	if (!email) err.email = ERROR_EMPTY_EMAIL;
	if (!ValidateEmail(email) && !err.email) err.email = ERROR_EMAIL;
	return err;
};

export const emailPhoneValidation = (phone) => {
	const err = {};
	if (!phone) err.phone = ERROR_EMPTY_PHONE;
	if (!ValidatePhone(phone)) err.phone = ERROR_PHONE;
	return err;
};

export const otpVerify = (otp, length_of_otp = 5) => {
	const err = {};
	if (otp.length < 1) {
		err.otp = ERROR_EMPTY_OTP;
	} else if (otp.length < length_of_otp) {
		err.otp = ERROR_FIELD_EMPTY;
	}
	return err;
};

export const emailValidation = (email, password) => {
	const err = {};
	if (!email) err.email = ERROR_EMPTY_EMAIL;
	if (!password) err.password = ERROR_EMPTY_PASSWORD;
	if (!ValidateEmail(email) && !err.email) err.email = ERROR_EMAIL;
	return err;
};

export const CaANZLogin = (email, firstName, surname, ack) => {
	const err = {};
	if (!email) err.email = ERROR_EMPTY_EMAIL;
	if (!firstName) err.firstName = ERROR_EMPTY_FIRST_NAME;
	if (!surname) err.surname = ERROR_EMPTY_SURNAME;
	if (!ack) err.acknowledge = ERROR_ACK_REQUIRED;
	if (!ValidateEmail(email) && !err.email) err.email = ERROR_EMAIL;
	return err;
};
