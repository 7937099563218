import moment from 'moment-timezone';

/**
 * Check if current date time lies between a date range.
 */
export const validDateTime = (start, end) => {
	// Take start and end with momentJS
	const startDate = moment(start);
	const endDate = moment(end);

	const currentDT = moment();

	if (currentDT >= startDate && currentDT <= endDate) {
		return true;
	}

	return false;
};

/**
 * Check if current date time is greater than the date time of live active end
 */
export const validateLiveEnd = (endDate) => {
	const currentDT = moment();
	const endMoment = moment(endDate);

	if (currentDT >= endMoment) {
		return true;
	}

	return false;
};

/**
 * Check if current time is before live active start
 */
export const validatePreLive = (liveActiveStart) => {
	const currentDT = moment();
	const preLive = moment(liveActiveStart);

	if (preLive > currentDT) {
		return true;
	}

	return false;
};

/**
 * Remove +61 or 0
 */
export const removePhonePrefix = (phone) => {
	if (phone.startsWith('+61')) {
		return phone.substring(3);
	} else if (phone.startsWith('0')) {
		return phone.substring(1);
	}
};

export const splitChoices = (choice) => {
	return choice.split(',');
};

export const endDateSplit = (date) => {
	return date.split(',')[2];
};

export const dateManager = (date) => {
	return moment.duration(moment().diff(date)).asSeconds();
};

export const formatDate = (date, format) => {
	let convert_date = new Date(date);
	return moment(convert_date).format(format);
};

export const timeCalc = 1000 * 60 * 60 * 24;
export const timeHours = 1000 * 60 * 60;
export const timeMinutes = 1000 * 60;

// Registration form
export const EVENT_SUBSCRIBE = 1;
export const SUBSCRIBE_BY_SESSION = 2;
export const AUTO_SUBSCRIBE_ALL = 3;

export const recallApi = [10000, 20000, 40000, 60000, 80000, 100000, 120000];
