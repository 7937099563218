import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

const WithLoader = (props) => {
	let { submitting, value, disabledState, style } = props;
	return (
		<Button
			type="submit"
			size="sm"
			className={style ? style : ''}
			disabled={disabledState}
		>
			{submitting ? (
				<>
					<Spinner animation="border" size="sm" />{' '}
					{value ? value : 'Submitting'}
				</>
			) : (
				'Submit'
			)}
		</Button>
	);
};

export default WithLoader;
