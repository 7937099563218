import React from 'react';
import { Row, Col, Card, CardGroup } from 'react-bootstrap';

const SystemInfo = () => {
	return (
		<>
			<Row className="my-5">
				<Col md="12">
					<div className="bold">Having trouble viewing?</div>
					<div>
						Check the browser recommendations / system requirements below. If
						the stream pauses (due to buffering) or fails to open, please try
						refreshing the browser page.
					</div>
				</Col>
			</Row>
			<Row className="my-5">
				<Col md="12">
					<CardGroup className="card-border">
						<Card>
							<Card.Body>
								<p className="text-center bold">Laptops</p>
								<p>Supported on Mac OSX 9+, Windows 8+</p>
								<p>* does require sound card with speakers or headphones</p>
							</Card.Body>
						</Card>
						<Card>
							<Card.Body>
								<p className="text-center bold">Recommended browser versions</p>
								<p>
									Chrome 58+ Firefox 52+ MS Edge 14+ Safari 10+ Internet
									Explorer 11+*
								</p>
								<div>
									<ul>
										<li>* IE9 + IE10 is not supported.</li>
										<li>* IE 11 is only supported on Windows 8+</li>
									</ul>
								</div>
							</Card.Body>
						</Card>
						<Card>
							<Card.Body>
								<p className="text-center bold">Supported devices/browsers</p>
								<p>
									Safari/Chrome on iPhone Safari/Chrome on iPad Chrome on
									Android
								</p>
							</Card.Body>
						</Card>
					</CardGroup>
				</Col>
			</Row>
		</>
	);
};

export default SystemInfo;
