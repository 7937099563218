export const getAuth = (state) => state.auth;

export const isLoggedIn = (state, slug) => {
	const auth = getAuth(state);
	let authentication = auth.authentication;
	if (!auth || !authentication) return false;

	for (let i = 0; i < authentication.length; i++) {
		if (slug === authentication[i].__eslug && authentication[i].__emsat) {
			return true;
		}
	}

	return false;
};

export const getAccessToken = (state, slug) => {
	const auth = getAuth(state);
	if (!auth || !auth.authentication) return '';

	let { authentication } = auth;

	for (let auth = 0; auth < authentication.length; auth += 1) {
		if (authentication[auth].__eslug === slug) {
			return authentication[auth].__emsat;
		}
	}

	return '';
};

export const getRefreshToken = (state, slug) => {
	const auth = getAuth(state);
	if (!auth || !auth.authentication) return '';

	let { authentication } = auth;

	for (let auth = 0; auth < authentication.length; auth += 1) {
		if (authentication[auth].__eslug === slug) {
			return authentication[auth].__emsrt;
		}
	}

	return '';
};
