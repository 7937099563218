import Axios from 'axios';

const COMMON_HEADERS = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

class VideoService {
	async request(url = '', method = 'POST', data = null) {
		let options = {};
		const headers = { ...COMMON_HEADERS };
		try {
			options = { url, method, headers };
			if (data) {
				const jsonData = JSON.stringify(data);
				options.data = jsonData;
			}
		} catch (e) {
			return e;
		}
		try {
			await Axios(options);
			return true;
		} catch (e) {
			return false;
		}
	}
}

export const videoService = new VideoService();
