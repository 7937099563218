import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SideAccordion from './SideAccordion';
import { getSessionInformation } from 'selectors/session';

const Session = (info) => {
	return (
		<p
			dangerouslySetInnerHTML={{
				__html: info.info,
			}}
		></p>
	);
};

const SessionInformation = (props) => {
	const [state, setState] = useState('');
	let { slug, id } = useParams();
	const { sessionInformation, label } = props;
	useEffect(() => {
		setState(sessionInformation(id));
	}, [sessionInformation, id, slug]);
	return (
		<>
			{state && (
				<div className="mb-4">
					<SideAccordion
						icon={<FontAwesomeIcon icon={faInfoCircle} />}
						title={label}
						component={<Session info={state} />}
					/>
				</div>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		sessionInformation: (id) => getSessionInformation(state, id),
	};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(SessionInformation);
