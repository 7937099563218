import Axios from 'axios';

const COMMON_HEADERS = {
	Accept: 'application/json',
	'Content-Type': 'application/json',
};

class HttpService {
	async request(method = 'get', url = '', data = null, headers = null) {
		let options = {};
		headers = { ...COMMON_HEADERS, ...headers };
		if (method === 'post') headers = { ...headers };

		try {
			options = { url, method, headers };
			if (data) {
				const jsonData = JSON.stringify(data);
				options.data = jsonData;
			}
		} catch (e) {
			return e;
		}
		try {
			const response = await Axios(options);
			return response;
		} catch (e) {
			try {
				let errorResponse = {
					message: e.response.data.data.errors,
					errorCode: e.response.status,
				};
				return errorResponse;
			} catch (err) {
				if (e.response) {
					return {
						message: e.message,
						errorCode: e.response.status,
					};
				}
				return {
					message: 'Network Error',
					errorCode: '',
				};
			}
		}
	}
}

export const httpService = new HttpService();
