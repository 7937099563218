import React from 'react';

// const Home = (props) => {
// 	const [state, setState] = useState({ events: [], error: '' });
// 	const { allEvents } = props;

// 	function callbackSuccess(data) {
// 		setState({ ...state, events: data.sort(sortEvents) });
// 	}

// 	function callbackError(error) {
// 		setState({ ...state, error: error.message });
// 	}
// 	useEffect(() => {
// 		allEvents(callbackSuccess, callbackError);
// 	}, []);

// 	const sortEvents = (a, b) => {
// 		const bandA = a.id;
// 		const bandB = b.id;

// 		let comparison = 0;
// 		if (bandA > bandB) {
// 			comparison = -1;
// 		} else if (bandA < bandB) {
// 			comparison = 1;
// 		}
// 		return comparison;
// 	};
// 	return (
// 		<div>
// 			<Suspense fallback={null}>
// 				<AllEvents events={state.events} />
// 			</Suspense>
// 			{state.error && <h4 className="invalid-feedback error">{state.error}</h4>}
// 		</div>
// 	);
// };

const Home = (props) => {
	props.history.push('/ec_hesta_21/login');
	return <></>;
};

export default Home;
