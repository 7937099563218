import React from 'react';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const EventHome = (props) => {
	const { slug } = useParams();
	props.history.push(`/${slug}/info`);
	return <div className="event-home"></div>;
};

const mapStateToProps = () => {
	return {};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(withRouter(EventHome));
