import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import WithLoader from 'components/Button/WithLoader';
import PATH from 'routes';
import { otpVerify } from './helper';
import { verifyOTP, requestOTP } from 'actions/auth';
import { isLoggedIn } from 'selectors/auth';
import { getContentBlock } from 'selectors/event';

const VerifyOTP = (props) => {
	const initialState = {
		otp: '',
		loading: false,
		submitting: false,
		serverResponse: '',
		resendSuccess: '',
	};

	const [state, setState] = useState(initialState);
	const [errors, setErrors] = useState(initialState);
	const { email, phone, slug, length_of_otp } = props.location.state.data;
	const { location, contentBlocks } = props;

	if (!location.state) {
		props.history.push(PATH.HOME);
	}

	const resendCallbackSuccess = () => {
		setState({
			...state,
			loading: false,
			resendSuccess: 'OTP sent successfully.',
		});
	};
	const resendCallbackError = () => {
		setState({ ...state, loading: false });
	};

	const resendOTP = () => {
		const { requestOTP } = props;
		setState({ ...state, loading: true });
		setErrors(initialState);
		requestOTP(email, phone, slug, resendCallbackSuccess, resendCallbackError);
	};

	const handleChange = (value) => {
		setState({ ...state, otp: value, serverResponse: '' });
	};

	const callbackSuccess = (data) => {
		let { video_session_id } = data.data;
		props.history.push(
			`/${location.state.data.slug}/watch-now/${video_session_id}`
		);
	};

	const callbackError = (err) => {
		setState({ ...state, submitting: false, otp: '', serverResponse: err });
	};

	const handleSubmit = (e) => {
		if (e) e.preventDefault();
		setState({ ...state, submitting: true, serverResponse: '' });

		setErrors(initialState);
		const err = otpVerify(state.otp, length_of_otp);
		if (err.otp) {
			setErrors(err);
			setState({ ...state, submitting: false, serverResponse: '' });
			return;
		}
		const { verifyOTP } = props;
		verifyOTP(
			email,
			phone,
			state.otp,
			location.state.data.slug,
			callbackSuccess,
			callbackError
		);
	};
	return (
		<Row className="remove-row-margin">
			<Col sm={8} md={4} className="mx-auto my-2">
				<div
					dangerouslySetInnerHTML={{
						__html: contentBlocks.otp_input_page,
					}}
				></div>
				<Card>
					<Card.Header className="login-header text-center">
						<div className="sub-title">Please enter the passcode</div>
						<div className="text">
							Your passcode has been sent to your{' '}
							{location.state && location.state.sendTo}.
						</div>
					</Card.Header>
					<Card.Body className="otp-body">
						<Form onSubmit={(e) => handleSubmit(e)} className="pin-form">
							<Form.Group>
								<Form.Label className="w-100">
									<div className="resendOTP" onClick={resendOTP}>
										Resend OTP?
									</div>
								</Form.Label>
								<OtpInput
									value={state.otp}
									onChange={handleChange}
									numInputs={length_of_otp}
									inputStyle="inputStyle"
									isInputNum
									separator={<span>-</span>}
								/>
								{errors.otp ? (
									<div className="invalid-feedback error">{errors.otp}</div>
								) : (
									<div className="invalid-feedback error">
										{state.serverResponse}
									</div>
								)}
								{state.resendSuccess && (
									<div className="invalid-feedback success">
										{state.resendSuccess}
									</div>
								)}
							</Form.Group>
							<div className="grid">
								<WithLoader submitting={state.submitting} value="Verifying" />
								<>
									<div className="cursor text" onClick={props.history.goBack}>
										Change send option?
									</div>
								</>
							</div>
						</Form>
						{state.loading && (
							<div className="spinner-overlay">
								<Spinner animation="border" />
							</div>
						)}
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
		contentBlocks: getContentBlock(state),
	};
};

const dispatchProps = {
	verifyOTP,
	requestOTP,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(VerifyOTP));
