import * as allTypes from 'actions/types';

const defaultAuthState = {
	__emsd: '',
	authentication: [],
};

export const auth = (state = defaultAuthState, action) => {
	switch (action.type) {
		case allTypes.AUTH_INFO_UPDATE: {
			const { userId, slug, accessToken, refreshToken } = action;
			let authentication = state.authentication;

			if (!authentication) return state;

			for (let i = 0; i < authentication.length; i++) {
				if (slug === authentication[i].__eslug) {
					return state;
				}
			}
			return {
				...state,
				__emsd: userId,
				authentication: [
					...state.authentication,
					{ __emsat: accessToken, __emsrt: refreshToken, __eslug: slug },
				],
			};
		}
		case allTypes.AUTH_LOGOUT_CONTROL: {
			const { slug } = action;
			let authentication = state.authentication;

			if (!authentication) return state;

			let logoutIndex = authentication
				.map(function (item) {
					return item.__eslug;
				})
				.indexOf(slug);
			if (logoutIndex > -1) {
				authentication.splice(logoutIndex, 1);
			}

			return { ...state, authentication: [...authentication] };
		}

		case allTypes.REFRESH_INFO_UPDATE: {
			const { access, refresh, slug } = action;
			let authentication = state.authentication;

			if (!authentication) return state;
			for (let i = 0; i < authentication.length; i++) {
				if (slug === authentication[i].__eslug) {
					authentication[i].__emsat = access;
					authentication[i].__emsrt = refresh;
					return {
						...state,
						authentication: [...state.authentication],
					};
				}
			}
			return state;
		}
		default:
			return state;
	}
};
