import { combineReducers } from 'redux';
import { auth } from './auth';
import { account } from './account';
import { session } from './session';
import { system } from './system';
import { event } from './event';

const rootReducer = combineReducers({ auth, account, session, system, event });

const appReducer = (state, action) => {
	return rootReducer(state, action);
};

export default appReducer;
