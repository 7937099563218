import React, { useState } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import WithLoader from 'components/Button/WithLoader';
import { getDisableEmail } from 'config/Config';
import PATH from 'routes';
import { emailPhoneValidation } from './helper';
import { removePhonePrefix } from 'util/helper';
import { requestOTP } from 'actions/auth';
import { getContentBlock } from 'selectors/event';

const GetOTP = (props) => {
	const { state } = props.location;
	const { otp_receive_page, otp_receive_page_footer } = props.contentBlocks;
	const initialState = {
		submitting: false,
		sendVia: 'phone',
		email: state ? state.data : '',
		slug: state ? state.slug : '',
		phone: '',
		emailDisabled: getDisableEmail(),
		serverError: '',
		length_of_otp: state ? state.length_of_otp : 5,
	};

	const [appState, setAppState] = useState(initialState);
	const [error, setErrors] = useState(initialState);

	if (!state) {
		props.history.push(PATH.HOME);
	}

	const callbackSuccess = () => {
		props.history.push({
			pathname: `/${appState.slug}/verify-otp`,
			state: {
				sendTo: appState.sendVia,
				data: {
					email: appState.email,
					phone: appState.phone ? removePhonePrefix(appState.phone) : '',
					slug: appState.slug,
					length_of_otp: appState.length_of_otp,
				},
			},
		});
	};

	const callbackError = (err) => {
		setErrors({ ...error, serverError: err });
		setAppState({ ...appState, submitting: false });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		setAppState({ ...appState, submitting: true });
		setErrors(initialState);
		const { phone, sendVia } = appState;

		if (sendVia === 'phone') {
			const err = emailPhoneValidation(phone);
			if (err.phone) {
				setAppState({ ...appState, submitting: false });
				setErrors({ ...error, phone: err.phone });
				return;
			}
		}

		const { requestOTP } = props;
		requestOTP(
			appState.email,
			removePhonePrefix(appState.phone),
			appState.slug,
			callbackSuccess,
			callbackError
		);
	};

	const handleChange = (e) => {
		e.preventDefault();
		setErrors(initialState);
		setAppState({
			...appState,
			sendVia: appState.sendVia === 'email' ? 'phone' : 'email',
			phone: '',
		});
	};

	return (
		<Row>
			<Col sm={6} md={6} className="mx-auto my-2">
				<div
					dangerouslySetInnerHTML={{
						__html: otp_receive_page,
					}}
				></div>
				<Card>
					<Card.Header className="login-header text-center">
						<div className="login">Get Your Passcode</div>
					</Card.Header>
					<Card.Body>
						<Form onSubmit={(e) => handleSubmit(e)}>
							<Form.Group controlId="exampleForm.ControlSelect1">
								<Form.Control
									as="select"
									onChange={(e) => handleChange(e)}
									value={appState.sendVia}
								>
									{appState.emailDisabled ? (
										<>
											<option value="phone">Send passcode via SMS</option>
										</>
									) : (
										<>
											<option value="phone">Send passcode via SMS</option>
											<option value="email">Send passcode via Email</option>
										</>
									)}
								</Form.Control>
							</Form.Group>
							{appState.sendVia === 'phone' && (
								<Form.Group controlId="exampleForm.ControlInput1">
									<Form.Control
										type="tel"
										placeholder="Your Mobile Number"
										autoComplete="off"
										name="phone"
										required="required"
										value={appState.phone}
										isInvalid={error.phone}
										onChange={(e) =>
											setAppState({ ...appState, phone: e.target.value })
										}
									/>
									<Form.Control.Feedback type="invalid">
										{error.phone}
									</Form.Control.Feedback>
									{appState.serverError && (
										<Form.Control.Feedback type="invalid">
											{appState.serverError}
										</Form.Control.Feedback>
									)}
								</Form.Group>
							)}
							<WithLoader submitting={appState.submitting} />
						</Form>
					</Card.Body>
				</Card>
				<div
					dangerouslySetInnerHTML={{
						__html: otp_receive_page_footer,
					}}
				></div>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
	};
};
const dispatchProps = {
	requestOTP,
};

export default connect(mapStateToProps, dispatchProps)(GetOTP);
