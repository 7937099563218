export const EVENT_SLUG_INFO = 'EVENT_SLUG_INFO';
export const TEXT = 1;
export const TEXTAREA = 2;
export const EMAIL = 3;
export const CHECKBOX = 4;
export const CHECKBOX_MULTIPLE = 5;
export const SELECT = 6;
export const SELECT_MULTIPLE = 7;
export const RADIO_MULTIPLE = 8;
export const FILE = 9;
export const DATE = 10;
export const DATE_TIME = 11;
export const NUMBER = 12;
export const URL = 13;
export const MULTIPLE_SESSION = 14;
export const CONFIRM_EMAIL = 15;
