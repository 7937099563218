import React from 'react';
import Form from 'react-bootstrap/Form';
import { Row, Col } from 'react-bootstrap';

const SessionForm = ({
	handleSelectAll,
	changeVideoSession,
	default_select,
	sessionFields,
	selectedSessions,
	defaultFirstTime,
	registrationsActive,
}) => {
	return (
		<>
			<div className="multiple-session">
				<h3>Select Your Sessions</h3>
				<Form.Group controlId="formBasicCheckbox">
					<Form.Check
						type="checkbox"
						label="Subscribe all Sessions"
						checked={default_select}
						value={default_select}
						onChange={(e) => handleSelectAll(e)}
						disabled={!registrationsActive}
					/>
				</Form.Group>
			</div>
			{sessionFields.map((session, index) => (
				<div className="mb-3" key={index}>
					<div key={index}>
						<div className="session-container mb-3" key={index}>
							<div className="session-name">
								<div className="line"></div>
								<div className="name">{session.name}</div>
								<div className="dashed">--------</div>
							</div>
							<div className="session-check">
								<Row>
									<Col md={8}>
										<div
											dangerouslySetInnerHTML={{
												__html: session.session_information,
											}}
											className="float-left"
										></div>
									</Col>
									<Col md={4}>
										<Form.Check
											label="Select this session"
											className="float-right"
											name={session.id}
											onChange={(e) => changeVideoSession(e)}
											disabled={!registrationsActive}
											checked={
												defaultFirstTime
													? default_select
													: selectedSessions &&
													  selectedSessions.includes(session.id)
											}
										/>
									</Col>
								</Row>
							</div>
						</div>
					</div>
				</div>
			))}
		</>
	);
};

export default SessionForm;
