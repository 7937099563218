export const ERROR_EMPTY_EMAIL = 'Please provide an email address.';
export const ERROR_EMAIL = 'Please enter a valid email format.';

export const ERROR_EMPTY_PHONE = 'Please provide phone number.';
export const ERROR_PHONE = 'Please enter a valid phone number.';

export const ERROR_EMPTY_OTP = 'OTP is required.';
export const ERROR_FIELD_EMPTY = 'All fields are required.';

export const ERROR_EMPTY_PASSWORD = 'Please provide a password';

export const ERROR_EMPTY_FIRST_NAME = 'First Name is required.';
export const ERROR_EMPTY_SURNAME = 'Surname is required.';
export const ERROR_ACK_REQUIRED = 'This field must be checked.';
