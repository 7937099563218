import EventHttpService from 'services/EventHttpService';
import { getEndPoint, getVideoTrackURL } from 'config/Config';
import { videoService } from 'services/VideoTrackService';

export const currentVideo = (video_session_id, slug) => {
	const completePath =
		getEndPoint() + `/sessions/current-video/${video_session_id}/`;
	return EventHttpService.get(completePath, null, slug);
};

export const assetVideo = (id, slug) => {
	const completePath = getEndPoint() + '/sessions/asset-video/';
	const payload = { video_session: id };
	return EventHttpService.post(completePath, slug, payload);
};

export const videoTracking = (
	video_timestamp,
	tenantId,
	userId,
	videoId,
	eventId,
	uniqueSessionId,
	mediaState
) => {
	const completePath = getVideoTrackURL();
	const payload = {
		tenant_id: tenantId,
		event_id: eventId,
		vs_id: videoId,
		user_id: userId,
		video_ts: video_timestamp,
		session_id: uniqueSessionId,
		media_state: mediaState,
	};
	return videoService.request(completePath, 'POST', payload);
};

export const submitQuestion = (videoId, question, is_anonymous, slug, user) => {
	const completePath = getEndPoint() + '/sessions/questions/';
	const payload = {
		video_session: videoId,
		question: question,
		is_anonymous: is_anonymous,
		user: user,
	};
	return EventHttpService.post(completePath, slug, payload);
};

export const getAllQuestions = (videoId, slug, userId) => {
	const completePath = getEndPoint() + '/sessions/all-questions/';
	const payload = {
		video_session_id: videoId,
		user_id: userId,
	};
	return EventHttpService.post(completePath, slug, payload);
};

export const getSessionPoll = (videoId, slug) => {
	const completePath = getEndPoint() + `/poll/session-poll/${videoId}/`;
	return EventHttpService.get(completePath, null, slug);
};

export const submitPoll = (pollId, choices, userId, slug) => {
	const completePath = getEndPoint() + `/poll/participate/`;
	const payload = { user_id: userId, poll_id: pollId, choices: choices };
	return EventHttpService.post(completePath, slug, payload);
};

export const voteQuestion = (rowId, voteState, slug, id) => {
	const completePath = getEndPoint() + `/sessions/question-like-dislike`;
	const payload = {
		question_id: rowId,
		vote_state: voteState,
		video_session_id: id,
	};
	return EventHttpService.post(completePath, slug, payload);
};

export const getUserSessions = (slug) => {
	const completePath = getEndPoint() + `/sessions/get-user-sessions`;
	let payload = { slug: slug };
	return EventHttpService.post(completePath, slug, payload);
};

export const updateUserSessions = (slug, session_id) => {
	const completePath = getEndPoint() + `/sessions/update-user-sessions`;
	let payload = { session_id };
	return EventHttpService.post(completePath, slug, payload);
};
