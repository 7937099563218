import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import { submitQuestion } from 'actions/session';
import { getUserInfo } from 'selectors/account';
import { getQuestionActive } from 'selectors/session';
import WithLoader from 'components/Button/WithLoader';

const Question = (props) => {
	const initial = {
		question: '',
		is_anonymous: false,
		error: '',
		loading: false,
		questionActive: false,
	};

	const [state, setState] = useState(initial);
	let { slug, id } = useParams();

	const { question_description_caption, getQuestionActive } = props;

	useEffect(() => {
		let questionActive = getQuestionActive(id);
		setState({ ...state, questionActive: questionActive });
	}, [id]);

	const questionCallBackSuccess = () => {
		setState({
			...state,
			loading: false,
			error: '',
			question: '',
			serverResponse: 'Successfully submitted.',
		});
	};

	const questionCallBackError = () => {
		setState({
			...state,
			loading: false,
			error: 'Network Error',
			question: '',
			serverResponse: '',
		});
	};
	const handleChange = (e) => {
		e.preventDefault();
		if (e.target.value.length <= 240) {
			setState({ ...state, question: e.target.value, error: '' });
		} else {
			setState({
				...state,
				error: `Only 240 characters are allowed.`,
			});
		}
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		if (state.loading) return;
		setState({ ...state, error: '', loading: true, serverResponse: '' });
		if (state.question.length < 5) {
			setState({
				...state,
				error: 'Question must be atleast 5 character long.',
				serverResponse: '',
			});
		} else {
			const { submitQuestion, getUserInfo } = props;
			let user = getUserInfo.userId;
			let videoId = id;
			submitQuestion(
				videoId,
				state.question,
				state.is_anonymous,
				slug,
				user,
				questionCallBackSuccess,
				questionCallBackError
			);
		}
	};
	return (
		<Form onSubmit={(e) => handleSubmit(e)}>
			<Form.Group controlId="formBasicEmail">
				<div className="questionCaption">{question_description_caption}</div>
				<Form.Control
					as="textarea"
					rows={3}
					value={state.question}
					onChange={(e) => handleChange(e)}
					required
					autoComplete="off"
					disabled={!state.questionActive}
				/>
				<Form.Text className="text-muted"></Form.Text>
				{state.error && <div className="error">{state.error}</div>}
				{state.serverResponse && (
					<div className="success text">{state.serverResponse}</div>
				)}
			</Form.Group>
			<Form.Check
				inline
				label="Post Anonymously"
				className="text"
				checked={state.is_anonymous}
				disabled={!state.questionActive}
				onChange={() =>
					setState({ ...state, is_anonymous: !state.is_anonymous })
				}
				type="checkbox"
			/>
			<div className="float-right">
				<WithLoader
					disabledState={!state.questionActive}
					submitting={state.loading}
					value="Send"
				/>
			</div>
		</Form>
	);
};

const mapStateToProps = (state) => {
	return {
		getUserInfo: getUserInfo(state),
		getQuestionActive: (id) => getQuestionActive(state, id),
	};
};
const dispatchProps = {
	submitQuestion,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(Question));
