import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { getContentBlock } from 'selectors/event';

const Footer = (props) => {
	const { contentBlocks } = props;
	return (
		<div className="footer">
			<div
				dangerouslySetInnerHTML={{
					__html: contentBlocks.footer_text,
				}}
			></div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		contentBlocks: getContentBlock(state),
	};
};
const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(withRouter(Footer));
