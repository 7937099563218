import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { isLoggedIn } from 'selectors/auth';
import PATHS from 'routes';
import { toast } from 'react-toastify';

import { authCheckManagerToken } from 'actions/auth';

const SpecialToken = (props) => {
	let { slug, url_token } = useParams();
	const { isLoggedIn } = props;
	if (isLoggedIn(slug)) {
		props.history.push(`/${slug}/info`);
	}

	function callbackSuccess() {
		toast.success('Welcome. Your are logged in.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		props.history.push(`/${slug}/info`);
	}

	const callbackError = () => {
		toast.error('Token does not match.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		props.history.push(PATHS.HOME);
	};

	useEffect(() => {
		if (!slug || !url_token) {
			props.history.push(PATHS.HOME);
		}
		const { authCheckManagerToken } = props;
		authCheckManagerToken(slug, url_token, callbackSuccess, callbackError);
	}, []);

	return '';
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = { authCheckManagerToken };

export default connect(
	mapStateToProps,
	dispatchProps
)(withRouter(SpecialToken));
