import { takeLatest, all, call, put } from 'redux-saga/effects';

import { getGoogleId } from 'api/system';
import { GET_GOOGLE_ID } from 'actions/types';
import { updateGoogleId } from 'actions/system';

function* handleGoogleId(action) {
	const { callbackSuccess, callbackError } = action;
	try {
		const res = yield call(getGoogleId);
		if (!res) callbackError();
		if (!res.status) {
			callbackError();
		} else {
			const { tag } = res.data;
			yield put(updateGoogleId(tag));
			callbackSuccess();
		}
	} catch (e) {
		callbackError();
	}
}

function* watchGoogleId() {
	yield takeLatest(GET_GOOGLE_ID, handleGoogleId);
}

export default function* systemSaga() {
	yield all([watchGoogleId()]);
}
