import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { withRouter, NavLink, Link } from 'react-router-dom';
import { toggleSidebar, toggleVideo } from 'actions/system';
import { getCurrentEventSlug } from 'selectors/event';
import { isLoggedIn } from 'selectors/auth';
import { getImageUrl } from 'helper/helper';
import { authLogoutRequest } from 'actions/auth';
import { firstEvent } from 'actions/event';
import { getContentBlock, clearContentData } from 'actions/event';

const Navigation = (props) => {
	let { location, currentSlug, isLoggedIn } = props;
	const { getContentBlock, clearContentData } = props;
	let slug = useRouteMatch('/:slug');
	const [state, setState] = useState({ slug: '', displayRegister: false });

	const callbackSuccess = (data) => {
		let register = false;
		if (
			data.data.has_registration &&
			data.data.registration_slug &&
			slug.params.slug
		)
			register = true;

		setState({
			...state,
			...data.data,
			slug: slug.params.slug,
			displayRegister: register,
		});
	};

	const callbackError = (data) => {
		setState({
			...state,
			...data.data,
			slug: slug.params.slug,
		});
	};

	useEffect(() => {
		if (slug && slug.params.slug) {
			if (state.slug) {
				if (state.slug !== slug.params.slug) {
					getContentBlock(slug.params.slug, callbackSuccess, callbackError);
				}
			} else {
				getContentBlock(slug.params.slug, callbackSuccess, callbackError);
			}
		} else {
			setState({});
			clearContentData();
		}
	}, [slug]);

	const logoutSuccess = () => {
		toast.success('Logout Success.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};
	const logoutError = () => {
		toast.error('Something went wrong.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const logout = () => {
		if (!currentSlug) return '';
		const { authLogoutRequest } = props;
		authLogoutRequest(currentSlug, logoutSuccess, logoutError);
	};
	return (
		<>
			{state.navigation_background_color && (
				<style>{`.navbar, #responsive-navbar-nav{\
					background: ${state.navigation_background_color} !important;\
				}
				#responsive-navbar-nav .nav-link{
					color: ${state.navigation_text_color} !important;
					font-size: ${state.navigation_font_size} !important;
				}
				.nav-selected{
					background: ${state.navigation_active_color} !important;
				}
				`}</style>
			)}
			<img
				src={
					state.header_img
						? state.header_img
						: `${getImageUrl('/assets/images/ec_hesta.jpg')}`
				}
				className="banner-img header-img"
			/>

			{
				<Navbar collapseOnSelect expand="lg">
					<Navbar.Toggle aria-controls="responsive-navbar-nav" />
					<Navbar.Collapse id="responsive-navbar-nav">
						<Nav className="mr-auto">
							{state.slug && state.agenda_header && (
								<Nav.Link
									as={NavLink}
									to={`/${state.slug}/agenda`}
									className={
										location.pathname.includes('agenda') && 'nav-selected'
									}
								>
									Agenda
								</Nav.Link>
							)}

							{state.slug && isLoggedIn(currentSlug) && (
								<Nav.Link
									as={NavLink}
									to={`/${state.slug}/info`}
									className={
										location.pathname.includes('info') && 'nav-selected'
									}
								>
									Your Session
								</Nav.Link>
							)}
						</Nav>
						<Nav>
							{isLoggedIn(currentSlug) && location.pathname !== '/' && (
								<Nav.Link className="nav-links logout" onClick={logout}>
									Logout
								</Nav.Link>
							)}
							{!isLoggedIn(currentSlug) && (
								<Nav.Link
									as={NavLink}
									to={`/${state.slug}/login`}
									className={
										(location.pathname.includes('login') ||
											location.pathname.includes('-otp')) &&
										'nav-selected'
									}
								>
									Login
								</Nav.Link>
							)}
							{state.displayRegister && !isLoggedIn(currentSlug) && (
								<Link
									to={`/${state.slug}/register/${state.registration_slug}`}
									className={`float-right ml1 nav-link ${
										location.pathname.includes('register') && 'nav-selected'
									}`}
								>
									Register Now
								</Link>
							)}
						</Nav>
					</Navbar.Collapse>
				</Navbar>
			}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		currentSlug: getCurrentEventSlug(state),
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	toggleSidebar,
	toggleVideo,
	authLogoutRequest,
	firstEvent,
	getContentBlock,
	clearContentData,
};

export default connect(mapStateToProps, dispatchProps)(withRouter(Navigation));
