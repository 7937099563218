import { takeLatest, all, call, put, select } from 'redux-saga/effects';
import {
	emailPassword,
	singleEventPassword,
	whitelistLogin,
	otpVerifyEmail,
	requestOTP,
	verifyOtp,
	logout,
	tokenLogin,
	resetPassword,
	accessTokenLogin,
	whitelistAndSinglePassword,
	emailOnly,
	checkManager,
} from 'api/auth';
import {
	authInfoUpdate,
	authLogoutControl,
	accountLogoutControl,
} from 'actions/auth';
import { accountInfoUpdate } from 'actions/account';
import {
	EMAIL_PASSWORD_LOGIN,
	SINGLE_EVENT_PASSWORD,
	WHITELIST_LOGIN,
	OTP_VERIFY_EMAIL,
	REQUEST_OTP,
	VERIFY_OTP,
	LOGOUT,
	TOKEN_LOGIN,
	AUTH_LOGOUT_REQUEST,
	RESET_PASSWORD,
	ACCESS_TOKEN_LOGIN,
	WHITELIST_SINGLE_PASSWORD,
	EMAIL_ONLY,
	MANAGER_TOKEN,
} from 'actions/types';
import { getAccessToken, getRefreshToken } from 'selectors/auth';

function* handleEmailPassword(action) {
	const {
		email,
		password,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	} = action;
	try {
		const res = yield call(
			emailPassword,
			email,
			password,
			slug,
			recaptchaValue
		);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
		} else {
			let { message } = res;
			callbackError(message);
		}
	} catch (e) {
		console.log('Auth Saga [Email and Password Error]:: ', e);
	}
}

function* watchEmailPassword() {
	yield takeLatest(EMAIL_PASSWORD_LOGIN, handleEmailPassword);
}

function* handleSingleEventPassword(action) {
	const {
		email,
		password,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	} = action;
	try {
		const res = yield call(
			singleEventPassword,
			email,
			password,
			slug,
			recaptchaValue
		);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
		} else {
			let { message } = res;
			callbackError(
				message['non_field_errors'] ? message['non_field_errors'] : message
			);
		}
	} catch (e) {
		console.log('Auth Saga [Single Event Password Error]:: ', e);
	}
}

function* watchSingleEvent() {
	yield takeLatest(SINGLE_EVENT_PASSWORD, handleSingleEventPassword);
}

function* handleWhitelist(action) {
	const { email, slug, recaptchaValue, callbackSuccess, callbackError } =
		action;
	try {
		const res = yield call(whitelistLogin, email, slug, recaptchaValue);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
		} else {
			let { message } = res;
			callbackError(
				message['non_field_errors']
					? message['non_field_errors']
					: message['email']
			);
		}
	} catch (e) {
		console.log('Auth Saga [Whitelist Login Error]:: ', e);
	}
}

function* watchWhitelist() {
	yield takeLatest(WHITELIST_LOGIN, handleWhitelist);
}

function* handleOtpVerifyEmail(action) {
	const { email, slug, recaptchaValue, callbackSuccess, callbackError } =
		action;
	try {
		const res = yield call(otpVerifyEmail, email, slug, recaptchaValue);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			let { message } = res;
			callbackError(message);
		}
	} catch (e) {
		console.log('Auth Saga [OTP Login  Error]:: ', e);
	}
}

function* watchOtpVerifyEmail() {
	yield takeLatest(OTP_VERIFY_EMAIL, handleOtpVerifyEmail);
}

function* handleRequestOtp(action) {
	const { email, phone, slug, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(requestOTP, email, phone, slug);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			let { message } = res;
			callbackError(message);
		}
	} catch (e) {
		console.log('Auth Saga [OTP Login  Error]:: ', e);
	}
}

function* watchRequestOtp() {
	yield takeLatest(REQUEST_OTP, handleRequestOtp);
}

function* handleVerifyOTP(action) {
	const { email, phone, otp, slug, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(verifyOtp, email, phone, otp, slug);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
			callbackSuccess(res.data);
		} else {
			let { message } = res;
			callbackError(message);
		}
	} catch (e) {
		console.log('Auth Saga [OTP Login  Error]:: ', e);
	}
}

function* watchVerifyOTP() {
	yield takeLatest(VERIFY_OTP, handleVerifyOTP);
}

function* handleLogout(action) {
	const { callbackSuccess, callbackError } = action;

	try {
		const accessToken = yield select(getAccessToken);
		const refreshToken = yield select(getRefreshToken);

		const loggingOut = yield call(logout, accessToken, refreshToken);
		if (loggingOut.errorCode === 404) return callbackError(loggingOut.message);
		yield put(authLogoutControl());
		yield put(accountLogoutControl());
		if (callbackSuccess) callbackSuccess();
	} catch (e) {
		if (callbackError) callbackError();
	}
}

function* watchLogout() {
	yield takeLatest(LOGOUT, handleLogout);
}

function* handleTokenLogin(action) {
	const { token, slug, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(tokenLogin, token, slug);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
		} else {
			let { message } = res;
			callbackError(message['non_field_errors'] || message);
		}
	} catch (e) {
		console.log('Auth Saga [Token Login Error]:: ', e);
	}
}

function* watchTokenLogin() {
	yield takeLatest(TOKEN_LOGIN, handleTokenLogin);
}

function* handleAuthLogoutRequest(action) {
	const { slug, logoutSuccess, logoutError } = action;
	try {
		const accessToken = yield select(getAccessToken, slug);
		const refreshToken = yield select(getRefreshToken, slug);
		const loggingOut = yield call(logout, accessToken, refreshToken);

		if (loggingOut.errorCode === 404) return logoutError(loggingOut.message);
		yield put(authLogoutControl(slug));
		if (loggingOut.data.logout && logoutSuccess) logoutSuccess();
		else logoutError();
	} catch (e) {
		if (logoutError) logoutError();
	}
}

function* WatchHandleAuthLogoutRequest() {
	yield takeLatest(AUTH_LOGOUT_REQUEST, handleAuthLogoutRequest);
}

function* handleResetPassword(action) {
	const { slug, email, handleSuccess, handleError } = action;
	try {
		const res = yield call(resetPassword, slug, email);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			handleSuccess(res.data);
		} else {
			handleError(res.data);
		}
	} catch (e) {
		handleError(e);
	}
}

function* watchResetPassword() {
	yield takeLatest(RESET_PASSWORD, handleResetPassword);
}

function* handleATLogin(action) {
	const { slug, token, handleSuccess, handleError } = action;
	try {
		const res = yield call(accessTokenLogin, token);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
			handleSuccess(res.data);
		} else {
			handleError(res.data);
		}
	} catch (e) {
		handleError(e);
	}
}

function* watchATLogin() {
	yield takeLatest(ACCESS_TOKEN_LOGIN, handleATLogin);
}

function* handleWhitelistAndSinglePassword(action) {
	const {
		email,
		password,
		slug,
		recaptchaValue,
		callbackSuccess,
		callbackError,
	} = action;
	try {
		const res = yield call(
			whitelistAndSinglePassword,
			email,
			password,
			slug,
			recaptchaValue
		);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
			callbackSuccess(res.data);
		} else {
			callbackError(res);
		}
	} catch (e) {
		callbackError(e);
	}
}

function* watchWhitelistAndSinglePassword() {
	yield takeLatest(WHITELIST_SINGLE_PASSWORD, handleWhitelistAndSinglePassword);
}

function* handleEmailOnly(action) {
	const { email, slug, recaptchaValue, callbackSuccess, callbackError } =
		action;
	try {
		const res = yield call(emailOnly, email, slug, recaptchaValue);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
			callbackSuccess(res.data);
		} else {
			callbackError(res);
		}
	} catch (e) {
		callbackError(e);
	}
}

function* watchEmailOnly() {
	yield takeLatest(EMAIL_ONLY, handleEmailOnly);
}

function* handleCheckManagerToken(action) {
	const { slug, url_token, callbackSuccess, callbackError } = action;

	try {
		const res = yield call(checkManager, slug, url_token);
		if (!res) throw new Error('connection error');
		if (!res.data) {
			callbackError();
		} else {
			const {
				userId,
				accessToken,
				refreshToken,
				email,
				firstName,
				lastName,
				tenantId,
				__schema,
			} = res.data.data;
			yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
			yield put(
				accountInfoUpdate(
					email,
					userId,
					firstName,
					lastName,
					tenantId,
					__schema
				)
			);
			callbackSuccess(res.data);
		}
	} catch (e) {
		if (callbackError) callbackError();
	}
}

function* watchManagerToken() {
	yield takeLatest(MANAGER_TOKEN, handleCheckManagerToken);
}
export default function* authSaga() {
	yield all([
		watchEmailPassword(),
		watchSingleEvent(),
		watchWhitelist(),
		watchOtpVerifyEmail(),
		watchRequestOtp(),
		watchVerifyOTP(),
		watchLogout(),
		watchTokenLogin(),
		WatchHandleAuthLogoutRequest(),
		watchResetPassword(),
		watchATLogin(),
		watchWhitelistAndSinglePassword(),
		watchEmailOnly(),
		watchManagerToken(),
	]);
}
