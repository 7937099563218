//Event
export const EVENT_SLUG_INFO = 'EVENT_SLUG_INFO';
export const ALL_EVENTS = 'ALL_EVENTS';
export const EVENT_INFORMATION = 'EVENT_INFORMATION';
export const UPDATE_EVENT_REDIRECT = 'UPDATE_EVENT_REDIRECT';
export const UPDATE_EVENT_SLUG = 'UPDATE_EVENT_SLUG';
export const EVENT_REGISTRATION = 'EVENT_REGISTRATION';
export const SUBMIT_EVENT_REGISTRATION = 'SUBMIT_EVENT_REGISTRATION';
export const FIRST_EVENT = 'FIRST_EVENT';
export const GET_CONTENT_BLOCK = 'GET_CONTENT_BLOCK';
export const SET_CONTENT_DATA = 'SET_CONTENT_DATA';
export const CLEAR_CONTENT_DATA = 'CLEAR_CONTENT_DATA';

// Authentication
export const EMAIL_PASSWORD_LOGIN = 'EMAIL_PASSWORD_LOGIN';
export const SINGLE_EVENT_PASSWORD = 'SINGLE_EVENT_PASSWORD';
export const WHITELIST_LOGIN = 'WHITELIST_LOGIN';
export const OTP_VERIFY_EMAIL = 'OTP_VERIFY_EMAIL';
export const REQUEST_OTP = 'REQUEST_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const TOKEN_LOGIN = 'TOKEN_LOGIN';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const ACCESS_TOKEN_LOGIN = 'ACCESS_TOKEN_LOGIN';
export const WHITELIST_SINGLE_PASSWORD = 'WHITELIST_SINGLE_PASSWORD';
export const EMAIL_ONLY = 'EMAIL_ONLY';
export const MANAGER_TOKEN = 'MANAGER_TOKEN';

// After Login
export const AUTH_INFO_UPDATE = 'AUTH_INFO_UPDATE';
export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';

// Logout
export const AUTH_LOGOUT_CONTROL = 'AUTH_LOGOUT_CONTROL';
export const REFRESH_INFO_UPDATE = 'REFRESH_INFO_UPDATE';
export const LOGOUT = 'LOGOUT';
export const ACCOUNT_LOGOUT_CONTROL = 'ACCOUNT_LOGOUT_CONTROL';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';

// Sessions
export const CURRENT_VIDEO = 'CURRENT_VIDEO';
export const ASSET_VIDEO = 'ASSET_VIDEO';
export const UPDATE_CURRENT_VIDEO = 'UPDATE_CURRENT_VIDEO';
export const UPDATE_ASSET_VIDEO = 'UPDATE_ASSET_VIDEO';

// Questions
export const SUBMIT_QUESTION = 'SUBMIT_QUESTION';
export const ALL_QUESTIONS = 'ALL_QUESTIONS';
export const MY_QUESTIONS = 'MY_QUESTIONS';
export const UPDATE_QUESTION_STATE = 'UPDATE_QUESTION_STATE';
export const VOTE_QUESTION = 'VOTE_QUESTION';

// Poll
export const GET_SESSION_POLL = 'GET_SESSION_POLL';
export const SUBMIT_POLL = 'SUBMIT_POLL';

// Video track logs
export const VIDEO_TRACKING_LOGS = 'VIDEO_TRACKING_LOGS';

// System Sidebar
export const UPDATE_SYSTEM = 'UPDATE_SYSTEM';
export const UPDATE_TOGGLE_VIDEO = 'UPDATE_TOGGLE_VIDEO';
export const UPDATE_SYSTEM_TOGGLE = 'UPDATE_SYSTEM_TOGGLE';
export const GET_GOOGLE_ID = 'GET_GOOGLE_ID';
export const UPDATE_GOOGLE_ID = 'UPDATE_GOOGLE_ID';
export const UP_GOOGLE_ID = 'UP_GOOGLE_ID';

// Video Session
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_VIDEO = 'TOGGLE_VIDEO';
export const GET_VIDEO_PLAYER = 'GET_VIDEO_PLAYER';
export const GET_USER_SESSIONS = 'GET_USER_SESSIONS';
export const UPDATE_USER_SESSIONS = 'UPDATE_USER_SESSIONS';
