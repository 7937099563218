import { toast } from 'react-toastify';

export function ErrorToast(msg, sec = 5000) {
	toast.error(msg, {
		position: 'bottom-right',
		autoClose: sec,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		pauseOnFocusLoss: false,
	});
}

export function SuccessToast(msg, sec = 5000) {
	toast.success(msg, {
		position: 'bottom-right',
		autoClose: sec,
		hideProgressBar: false,
		closeOnClick: true,
		pauseOnHover: false,
		draggable: true,
		progress: undefined,
		pauseOnFocusLoss: false,
	});
}
