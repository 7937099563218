import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { isLoggedIn } from 'selectors/auth';
import { allQuestions, voteQuestion } from 'actions/session';
import { getSchemaName, getUserInfo } from 'selectors/account';
import { pusherConfig } from 'helper/pusher';
import { getPusherChannel } from 'config/Config';

const AllQuestions = (props) => {
	let { slug, id } = useParams();
	let { params } = props.match;
	const { allQuestions, isLoggedIn, voteQuestion, getUserInfo } = props;
	const initial = {
		data: [],
	};
	const [state, setState] = useState(initial);

	const callbackSuccess = (data) => {
		setState({ data: data.sort(sortQuestion) });
	};
	const callbackError = () => {
		console.log('error');
	};
	useEffect(() => {
		let videoId = id;
		if (isLoggedIn(slug)) {
			allQuestions(videoId, slug, callbackSuccess, callbackError);
		}
	}, [id, slug]);

	const voteSuccess = () => console.log('success');

	const voteError = () => console.log('error');

	const handleLikeDislike = (e, rowId, voteState) => {
		let data = state.data.filter(function (data) {
			return data.questionId === rowId;
		});
		if (voteState) {
			if (data && data.length > 0) {
				data[0].questionVote = true;
			}
		} else {
			if (data && data.length > 0) {
				data[0].questionVote = false;
			}
		}

		if (isLoggedIn(slug)) {
			voteQuestion(rowId, voteState, slug, id, voteSuccess, voteError);
		}
	};

	useEffect(() => {
		const channel = pusherConfig().subscribe(getPusherChannel());
		const { schema } = props;
		const currentSchema = schema;
		channel.bind(`${currentSchema}-${id}-question-broadcast`, (newData) => {
			if (
				newData.videoSessionId === parseInt(id) &&
				parseInt(params.id) === newData.videoSessionId
			) {
				if (newData.delete) {
					let stateData = state.data
						.map(function (item) {
							return item.questionId;
						})
						.indexOf(newData.questionId);
					if (stateData > -1) state.data.splice(stateData, 1);
					setState({ ...state, data: [...state.data] });
				} else {
					delete newData['videoSessionId'];
					let sortableData = [newData, ...state.data].sort(sortQuestion);
					setState({ ...state, data: sortableData });
				}
			}
		});

		channel.bind(`${currentSchema}-${id}-question-vote`, (newData) => {
			if (
				newData.videoSessionId === parseInt(id) &&
				parseInt(params.id) === newData.videoSessionId
			) {
				let updateVoteCount = state.data.filter(function (data) {
					return data.questionId === newData.questionId;
				});
				if (updateVoteCount && updateVoteCount.length > 0) {
					updateVoteCount[0].upVote = newData.upVote;
					setState({ ...state, data: [...state.data.sort(sortQuestion)] });
				}
			}
		});

		return () => {
			channel.unbind(`${currentSchema}-${id}-question-broadcast`);
			channel.unbind(`${currentSchema}-${id}-question-vote`);
		};
	}, [params, state.data]);

	const sortQuestion = (a, b) => {
		const bandA = a.upVote;
		const bandB = b.upVote;

		let comparison = 0;
		if (bandA > bandB) {
			comparison = -1;
		} else if (bandA < bandB) {
			comparison = 1;
		}
		return comparison;
	};

	return (
		<Card className="live-q-a">
			{state.data.length > 0 ? (
				<ListGroup variant="flush">
					{state.data.map((question, index) => (
						<ListGroup.Item key={index} id={question.id}>
							<div className="question__user bold">
								{question.isAnonymous
									? 'Anonymous'
									: question.user.substring(0, 25)}
							</div>
							<div className="question__container">
								<div className="question__question">{question.question}</div>
								<div className="question__icons">
									{question.userId ? (
										question.userId !== getUserInfo.userId ? (
											<>
												<FontAwesomeIcon
													icon={faThumbsDown}
													onClick={(e) =>
														handleLikeDislike(e, question.questionId, false)
													}
													id={`down-${question.questionId}`}
													className={`${
														question.questionVote !== null
															? question.questionVote
																? ''
																: 'question__dislike'
															: ''
													}`}
												/>
												<FontAwesomeIcon
													icon={faThumbsUp}
													onClick={(e) =>
														handleLikeDislike(e, question.questionId, true)
													}
													id={`up-${question.questionId}`}
													className={`${
														question.questionVote !== null
															? question.questionVote
																? 'question__like'
																: ''
															: ''
													}`}
												/>
											</>
										) : (
											''
										)
									) : (
										!question.myQuestion && (
											<>
												<FontAwesomeIcon
													icon={faThumbsDown}
													onClick={(e) =>
														handleLikeDislike(e, question.questionId, false)
													}
													id={`down-${question.questionId}`}
													className={`${
														question.questionVote !== null
															? question.questionVote
																? ''
																: 'question__dislike'
															: ''
													}`}
												/>
												<FontAwesomeIcon
													icon={faThumbsUp}
													onClick={(e) =>
														handleLikeDislike(e, question.questionId, true)
													}
													id={`up-${question.questionId}`}
													className={`${
														question.questionVote !== null
															? question.questionVote
																? 'question__like'
																: ''
															: ''
													}`}
												/>
											</>
										)
									)}
								</div>
							</div>
						</ListGroup.Item>
					))}
				</ListGroup>
			) : (
				<ListGroup.Item className="text-center">No data found</ListGroup.Item>
			)}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
		schema: getSchemaName(state),
		getUserInfo: getUserInfo(state),
	};
};

const dispatchProps = {
	allQuestions,
	voteQuestion,
};

export default connect(
	mapStateToProps,
	dispatchProps
)(withRouter(AllQuestions));
