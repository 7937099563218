import React, { useState, useEffect } from 'react';
import { withRouter, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { getMyQuestions } from 'selectors/session';

const MyQuestions = (props) => {
	let { slug, id } = useParams();
	const initial = {
		questions: [],
		error: '',
		loading: true,
	};
	const { getMyQuestions } = props;
	const [state, setState] = useState(initial);
	useEffect(() => {
		let myQuestions = getMyQuestions(id);
		setState({ ...state, questions: myQuestions, loading: false });
	}, [slug, id, getMyQuestions]);
	return (
		<Card className="live-q-a">
			{state.questions && state.questions.length > 0 ? (
				<ListGroup variant="flush">
					{state.questions.map((question, index) => (
						<ListGroup.Item key={index}>{question.question}</ListGroup.Item>
					))}
				</ListGroup>
			) : (
				<ListGroup.Item className="text-center">No data found</ListGroup.Item>
			)}
		</Card>
	);
};

const mapStateToProps = (state) => {
	return {
		getMyQuestions: (id) => getMyQuestions(state, id),
	};
};

const dispatchProps = {};

export default connect(mapStateToProps, dispatchProps)(withRouter(MyQuestions));
