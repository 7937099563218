import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import { getUserSessions, updateUserSessions } from 'actions/session';
import { toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

let interval;
let submitInterval;
let submitRecall = 0;

const EditSession = (props) => {
	const { getUserSessions, updateUserSessions } = props;
	const { slug } = useParams();

	const [state, setState] = useState({
		dates: [
			'Wednesday 12 May, 9am - 10am AEST',
			'Wednesday 12 May, 11am - 12pm AEST',
		],
		sessions: [],
		submitting: false,
		selectedSessions: [],
		disabled: false,
		loading: true,
	});

	const callbackSuccess = (data) => {
		console.log('');
		if (interval) clearInterval(interval);
		let sortData = data.data.sort(sortSessions);
		let selectedSessions = sortData
			.filter((session) => {
				return session.selected;
			})
			.map((session) => {
				return session.id;
			});

		setState({
			...state,
			sessions: sortData,
			selectedSessions: selectedSessions,
			loading: false,
		});
	};

	const callbackError = (err) => {
		console.log(err);
	};

	const submitSuccess = (data) => {
		if (submitInterval) clearInterval(submitInterval);

		if (data) {
			setState({ ...state, submitting: false });
			toast.success(<div>Your session has been updated.</div>, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
			});
			props.history.push(`/${slug}/info`);
		}
	};

	const submitError = (err) => {
		if (
			err.message &&
			err.message.non_field_errors &&
			err.message.non_field_errors.length > 0
		) {
			setState({ ...state, submitting: false, disabled: false });
			toast.error(<div>{err.message.non_field_errors[0]}</div>, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				pauseOnFocusLoss: false,
			});
		} else {
			if (submitInterval) clearInterval(submitInterval);
			setState({ ...state, disabled: true, submitting: false });
			if (submitRecall !== 2) submitRecall++;
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (state.selectedSessions.length < 1)
			toast.error(<div>Please select your session.</div>, {
				position: 'bottom-right',
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: false,
				draggable: true,
				progress: undefined,
				pauseOnFocusLoss: false,
			});
		else {
			setState({ ...state, submitting: true });
			updateUserSessions(
				slug,
				state.selectedSessions,
				submitSuccess,
				submitError
			);
		}
	};

	const handleChange = (e) => {
		const { id } = e.target;
		let index = state.selectedSessions.indexOf(parseInt(id));
		if (index > -1) state.selectedSessions.splice(index, 1);
		else state.selectedSessions.push(parseInt(id));

		setState({ ...state, selectedSessions: state.selectedSessions });
	};

	const sortSessions = (a, b) => {
		const sessionA = a.id;
		const sessionB = b.id;

		let comparison = 0;
		if (sessionA < sessionB) {
			comparison = -1;
		} else if (sessionA > sessionB) {
			comparison = 1;
		}
		return comparison;
	};

	useEffect(() => {
		getUserSessions(slug, callbackSuccess, callbackError);
	}, []);
	return (
		<div className="event-details">
			<Row>
				<Col md={12}>
					<div className="sub-title bold">My Sessions</div>
				</Col>
			</Row>
			{state.loading ? (
				<>Loading...</>
			) : (
				<Form onSubmit={(e) => handleSubmit(e)}>
					<Row className="mt-3">
						<Col md={12} sm={12}>
							{state.sessions.map((session, index) => (
								<div key={index}>
									<div className="session-container mb-3">
										<div className="session-name">
											<div className="line"></div>
											<div className="name">{session.name}</div>
											<div className="session-date">{state.dates[index]}</div>
											<div className="dashed">--------</div>
										</div>
										<div className="session-check">
											<Row>
												<Col md={8}>
													<div
														dangerouslySetInnerHTML={{
															__html: session.session_information,
														}}
														className="float-left"
													></div>
												</Col>
												<Col md={4}>
													<Form.Check
														label="Select this session"
														className="float-right"
														id={session.id}
														checked={state.selectedSessions.includes(
															parseInt(session.id)
														)}
														onChange={(e) => handleChange(e)}
													/>
												</Col>
											</Row>
										</div>
									</div>
								</div>
							))}
						</Col>
						<Col md={12} sm={12}>
							<Button
								type="submit"
								size="sm"
								variant="dark mx-auto"
								className={'form-builder-theme'}
								disabled={state.disabled}
							>
								{state.submitting ? (
									<>
										<Spinner animation="border" size="sm" /> Submitting
									</>
								) : (
									<>Update Sessions</>
								)}
							</Button>
						</Col>
					</Row>
				</Form>
			)}
		</div>
	);
};

const mapStateToProps = () => {
	return {};
};
const dispatchProps = { getUserSessions, updateUserSessions };

export default connect(mapStateToProps, dispatchProps)(withRouter(EditSession));
