import Pusher from 'pusher-js';
import { getPusherKey, getPusherCluster } from 'config/Config';

export const pusherConfig = () => {
	const pusher = new Pusher(getPusherKey(), {
		cluster: getPusherCluster(),
		encrypted: true,
	});

	return pusher;
};
