import { useEffect } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withRouter, useParams } from 'react-router-dom';
import { isLoggedIn } from 'selectors/auth';
import PATHS from 'routes';
import { tokenLogin } from 'actions/auth';

const TokenLogin = (props) => {
	let { slug, token_login } = useParams();
	if (props.isLoggedIn(slug)) {
		props.history.push(`/${slug}/info`);
	}

	function callbackSuccess() {
		toast.success('Welcome. Your are logged in.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		props.history.push(`/${slug}/info`);
	}

	const callbackError = () => {
		toast.error('Token does not match.', {
			position: 'bottom-right',
			autoClose: 5000,
			hideProgressBar: true,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
		props.history.push(PATHS.HOME);
	};

	useEffect(() => {
		if (!token_login) {
			props.history.push(PATHS.HOME);
		}
		const { tokenLogin } = props;
		tokenLogin(token_login, slug, callbackSuccess, callbackError);
	}, []);

	return '';
};

const mapStateToProps = (state) => {
	return { isLoggedIn: (slug) => isLoggedIn(state, slug) };
};
const dispatchProps = { tokenLogin };

export default connect(mapStateToProps, dispatchProps)(withRouter(TokenLogin));
