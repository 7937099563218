import React, { useEffect, useState, lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { isLoggedIn } from 'selectors/auth';
import { eventSlug } from 'actions/event';
import PATH from 'routes';
import { Helmet } from 'react-helmet';
import { getContentBlock } from 'selectors/event';

const EmailPhoneWithOTP = lazy(() =>
	import('components/Login/EmailPhoneWithOTP')
);

const EmailPassword = lazy(() => import('components/Login/EmailPassword'));

const SingleEventPassword = lazy(() =>
	import('components/Login/SingleEventPassword')
);

const Whitelist = lazy(() => import('components/Login/Whitelist'));
const WhitelistAndSingleEventPassword = lazy(() =>
	import('components/Login/WhitelistAndEventPassword')
);
const EmailOnlyLogin = lazy(() => import('components/Login/EmailOnlyLogin'));

const Event = (props) => {
	const [state, setState] = useState({
		component: '',
		eventInformation: '',
		name: '',
		all_sessions: '',
		site_key: '',
		loginInfo: '',
	});
	let { slug } = useParams();
	const { eventSlug, contentBlocks } = props;

	const callbackSuccess = (data) => {
		let {
			description,
			name,
			all_sessions,
			site_key,
			login_modes,
			length_of_otp,
		} = data.data;

		switch (login_modes) {
			case 1:
				setState({
					...state,
					component: (
						<EmailPhoneWithOTP
							site_key={site_key}
							length_of_otp={length_of_otp || 5}
						/>
					),
					eventInformation: description,
					name: name,
					all_sessions: all_sessions,
				});
				break;
			case 2:
				setState({
					...state,
					component: <EmailPassword site_key={site_key} />,
					eventInformation: description,
					name: name,
					all_sessions: all_sessions,
				});
				break;
			case 3:
				setState({
					...state,
					component: <Whitelist site_key={site_key} />,
					eventInformation: description,
					name: name,
					all_sessions: all_sessions,
				});
				break;
			case 4:
				setState({
					...state,
					component: <SingleEventPassword site_key={site_key} />,
					eventInformation: description,
					name: name,
					all_sessions: all_sessions,
				});
				break;
			case 6:
				setState({
					...state,
					component: <WhitelistAndSingleEventPassword site_key={site_key} />,
					eventInformation: description,
					name: name,
					all_sessions: all_sessions,
				});
				break;
			case 7:
				setState({
					...state,
					component: <EmailOnlyLogin site_key={site_key} />,
					eventInformation: description,
					name: name,
					all_sessions: all_sessions,
				});
				break;
			default:
				setState({
					...state,
					component: <EmailPassword site_key={site_key} />,
					eventInformation: description,
					name: name,
					all_sessions: all_sessions,
				});
				break;
		}
	};

	const callbackError = () => {
		return props.history.push(PATH.HOME);
	};

	useEffect(() => {
		eventSlug(slug, callbackSuccess, callbackError);
	}, [slug]);

	return (
		<Suspense fallback={null}>
			<Helmet>
				<title>{state.name ? state.name : ''}</title>
			</Helmet>
			<Row className="justify-content-md-center">
				<Col sm={6} md={6} className="float-left">
					{contentBlocks.sign_in_text && (
						<div
							dangerouslySetInnerHTML={{
								__html: contentBlocks.sign_in_text,
							}}
						></div>
					)}
					{state.component}
				</Col>
			</Row>
		</Suspense>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
		contentBlocks: getContentBlock(state),
	};
};
const dispatchProps = {
	eventSlug,
};

export default connect(mapStateToProps, dispatchProps)(Event);
