import React, { useState } from 'react';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { withRouter, useParams } from 'react-router-dom';
import { isLoggedIn } from 'selectors/auth';
import WithLoader from 'components/Button/WithLoader';
import { requestPasswordChange } from 'actions/auth';
import { ValidateEmail } from 'util/Validator';

const ResetPassword = (props) => {
	const initial = { email: '', success: '', error: '', submitting: false };
	const [state, setState] = useState(initial);
	let { slug } = useParams();

	if (props.isLoggedIn(slug)) {
		props.history.push(`/${slug}/info`);
	}

	const handleSuccess = () => {
		setState({
			...state,
			email: '',
			error: '',
			success: 'If email exists, then you will receive Password Change Email.',
			submitting: false,
		});
	};

	const handleError = (e) => console.log(e);

	const handleSubmit = (e) => {
		e.preventDefault();
		setState({ ...state, submitting: true, success: '', error: '' });
		if (!state.email) {
			setState({ ...state, error: 'Email is required.' });
			return;
		} else if (!ValidateEmail(state.email.toLowerCase())) {
			setState({ ...state, error: 'Email is not valid' });
			return;
		} else {
			const { requestPasswordChange } = props;
			requestPasswordChange(slug, state.email, handleSuccess, handleError);
		}
	};
	return (
		<Row>
			<Col sm={6} md={6} className="mx-auto">
				<Card>
					<Card.Header className="login-header text-center">
						<div className="login">Forget Password ?</div>
					</Card.Header>
					<Card.Body>
						<Form onSubmit={(e) => handleSubmit(e)}>
							<Form.Group controlId="exampleForm.ControlInput1">
								<Form.Label>Enter your email address</Form.Label>
								<Form.Control
									type="email"
									placeholder="Email address"
									name="email"
									value={state.email}
									isInvalid={state.error}
									onChange={(e) =>
										setState({ ...state, email: e.target.value.toLowerCase() })
									}
								/>
								<Form.Control.Feedback type="invalid">
									{state.error}
								</Form.Control.Feedback>
								{state.success && (
									<p className="success text">{state.success}</p>
								)}
							</Form.Group>
							<WithLoader submitting={state.submitting} />
						</Form>
					</Card.Body>
				</Card>
			</Col>
		</Row>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
	};
};
const dispatchProps = {
	requestPasswordChange,
};

export default connect(
	mapStateToProps,
	dispatchProps
)(withRouter(ResetPassword));
