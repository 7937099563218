import React, { useState, useRef } from 'react';
import { faPoll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SideAccordion from './SideAccordion';
import PollActivity from './PollActivity';

const Polls = (props) => {
	const { label } = props;
	const [state, setState] = useState({ poll: false, displayPoll: true });
	let pollAccordion = useRef();
	const openPoll = () => {
		setState({ ...state, poll: true });
	};
	const hidePoll = () => {
		pollAccordion.current.classList.add('hide-accordion');
	};
	const showPoll = () => {
		pollAccordion.current.classList.remove('hide-accordion');
	};
	return (
		<div className="mb-4" ref={pollAccordion}>
			{state.displayPoll && (
				<SideAccordion
					icon={<FontAwesomeIcon icon={faPoll} />}
					component={
						<PollActivity
							showPoll={showPoll}
							hidePoll={hidePoll}
							openPoll={openPoll}
						/>
					}
					title={label}
					openPoll={state.poll}
					newClass="poll"
				/>
			)}
		</div>
	);
};

export default Polls;
