import { takeLatest, all, call, put } from 'redux-saga/effects';

import {
	slugVerify,
	allEvents,
	eventInformation,
	eventRegistration,
	submitEventRegistration,
	getVideoPlayer,
	getContentBlock,
} from 'api/event';
import {
	EVENT_SLUG_INFO,
	ALL_EVENTS,
	EVENT_INFORMATION,
	EVENT_REGISTRATION,
	SUBMIT_EVENT_REGISTRATION,
	GET_VIDEO_PLAYER,
	GET_CONTENT_BLOCK,
} from 'actions/types';
import { authInfoUpdate } from 'actions/auth';
import { accountInfoUpdate } from 'actions/account';
import { eventRedirectUpdate, setContentData } from 'actions/event';
import { upGoogleId } from 'actions/system';

function* handleEventSlug(action) {
	const { slug, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(slugVerify, slug);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			let { message } = res;
			callbackError(message['non_field_errors']);
		}
	} catch (e) {
		console.log('Event Saga [Slug Error]:: ', e);
	}
}

function* watchEventSlug() {
	yield takeLatest(EVENT_SLUG_INFO, handleEventSlug);
}

function* handleAllEvents(action) {
	const { callbackSuccess, callbackError } = action;
	try {
		const res = yield call(allEvents);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			callbackError(res);
		}
	} catch (e) {
		console.log('Event Saga [All Events Error]:: ', e);
	}
}

function* watchAllEvents() {
	yield takeLatest(ALL_EVENTS, handleAllEvents);
}

function* handleEventInformation(action) {
	const { slug, callbackSuccess, callbackError } = action;
	try {
		const res = yield call(eventInformation, slug);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			yield put(eventRedirectUpdate(res.data));
			callbackSuccess(res.data);
		} else {
			callbackError(res);
		}
	} catch (e) {
		console.log('Event Saga [All Events Error]:: ', e);
	}
}

function* watchEventInformation() {
	yield takeLatest(EVENT_INFORMATION, handleEventInformation);
}

function* handleEventRegistration(action) {
	const { slug, callbackSuccess, callbackError } = action;

	try {
		const res = yield call(eventRegistration, slug);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			callbackError(res);
		}
	} catch (e) {
		console.log('Event Saga [Event Registration Error]:: ', e);
	}
}

function* watchEventRegistration() {
	yield takeLatest(EVENT_REGISTRATION, handleEventRegistration);
}

function* handelSubmitEventReg(action) {
	const { slug, submitForm, selectedSessions, submitSuccess, submitError } =
		action;

	try {
		const res = yield call(
			submitEventRegistration,
			slug,
			submitForm,
			selectedSessions
		);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			const { login } = res.data;
			// Login here if login key is true
			if (login) {
				const {
					userId,
					accessToken,
					refreshToken,
					email,
					firstName,
					lastName,
					tenantId,
					__schema,
					slug,
				} = res.data.data;
				yield put(authInfoUpdate(userId, slug, accessToken, refreshToken));
				yield put(
					accountInfoUpdate(
						email,
						userId,
						firstName,
						lastName,
						tenantId,
						__schema
					)
				);
			}
			submitSuccess(res.data);
		} else {
			submitError(res);
		}
	} catch (e) {
		console.log('Event Saga [Event Registration Error]:: ', e);
	}
}

function* watchSubmitEventReg() {
	yield takeLatest(SUBMIT_EVENT_REGISTRATION, handelSubmitEventReg);
}

function* handleVideoPlayer(action) {
	const { id, slug, callbackSuccess, callbackError } = action;

	try {
		const res = yield call(getVideoPlayer, id, slug);
		if (!res) throw new Error('Connection Error');
		if (res.status) {
			callbackSuccess(res.data);
		} else {
			callbackError(res);
		}
	} catch (e) {
		console.log('Event Saga [Video Player Fetch Error]:: ', e);
		callbackError();
	}
}

function* watchVideoPlayer() {
	yield takeLatest(GET_VIDEO_PLAYER, handleVideoPlayer);
}

function* handleContentBlock(action) {
	const { slug, callbackSuccess, callbackError } = action;

	try {
		const res = yield call(getContentBlock, slug);
		if (!res) throw new Error('Connection Error');

		if (res.status && res.status !== 204) {
			yield put(setContentData(res.data));
			const { googleID } = res.data.data;
			yield put(upGoogleId(googleID));
			callbackSuccess(res.data);
		} else {
			callbackError(res);
		}
	} catch (e) {
		console.log('Event Saga [Content Block Fetch Error]:: ', e);
		callbackError();
	}
}

function* watchContentBlock() {
	yield takeLatest(GET_CONTENT_BLOCK, handleContentBlock);
}

export default function* eventSaga() {
	yield all([
		watchEventSlug(),
		watchAllEvents(),
		watchEventInformation(),
		watchEventRegistration(),
		watchSubmitEventReg(),
		watchVideoPlayer(),
		watchContentBlock(),
	]);
}
