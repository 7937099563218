import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import { getSessionPoll, submitPoll } from 'actions/session';
import { isLoggedIn } from 'selectors/auth';
import WithLoader from 'components/Button/WithLoader';
import { getSchemaName } from 'selectors/account';
import { pusherConfig } from 'helper/pusher';
import { getPusherChannel } from 'config/Config';

const PollActivity = (props) => {
	let { slug, id } = useParams();
	let { params } = props.match;
	const initial = {
		poll: {},
		loading: true,
		hasPoll: false,
		submitting: false,
		hasParticipated: false,
		error: '',
		recallPoll: false,
	};
	const [state, setState] = useState(initial);
	const [checkbox, setCheckbox] = useState([]);
	const [radio, setRadio] = useState([]);

	const { openPoll, getSessionPoll, showPoll } = props;

	useEffect(() => {
		const channel = pusherConfig().subscribe(getPusherChannel());
		const { schema } = props;
		const currentSchema = schema;
		channel.bind(`${currentSchema}-${id}-send-alert`, (data) => {
			if (
				data.videoSessionId === parseInt(id) &&
				parseInt(params.id) === data.videoSessionId
			) {
				if (data.remove) {
					setState({ ...state, hasPoll: false, loading: false });
				} else {
					showPoll();
					let newPoll = {
						choices: data.choices,
						id: data.id,
						poll_type: data.poll_type,
						question: data.question,
					};
					callbackSuccess(newPoll);
					toast.success(`${data.frontendPushMessage}`, {
						position: 'bottom-right',
						autoClose: 5000,
						hideProgressBar: false,
						closeOnClick: true,
						pauseOnHover: true,
						draggable: true,
						progress: undefined,
					});
					openPoll();
				}
			}
		});
		return () => channel.unbind(`${currentSchema}-${id}-send-alert`);
	}, [params]);

	const callbackSuccess = (data) => {
		let { question, id, poll_type, choices } = data;
		if (!poll_type)
			setCheckbox(
				choices.map((d) => {
					return {
						select: false,
						choice: d.choice,
						id: d.id,
					};
				})
			);
		else
			setRadio(
				choices.map((d) => {
					return {
						select: false,
						choice: d.choice,
						id: d.id,
					};
				})
			);
		showPoll();
		setState({
			...state,
			loading: false,
			hasPoll: true,
			poll: { question: question, id: id, pollType: poll_type },
			choices: choices,
			recallPoll: false,
		});
	};
	const callbackError = () => {
		setState({ ...state, loading: false, hasPoll: false });
	};

	useEffect(() => {
		getSessionPoll(id, slug, callbackSuccess, callbackError);
	}, [slug, id, state.recallPoll === true]);

	const submitSuccess = () => {
		setState({
			...state,
			submitting: false,
			hasParticipated: true,
			error: '',
			hasPoll: false,
			poll: {},
		});
		toast.success('Thank you for participating.', {
			position: 'bottom-right',
			autoClose: true,
			hideProgressBar: false,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: true,
			progress: undefined,
		});
	};

	const submitError = () => {
		setState({
			...state,
			submitting: false,
			hasParticipated: false,
			error: 'Failed to submit',
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let data = (state.poll.pollType ? radio : checkbox).filter(function (d) {
			return d.select;
		});
		if (data.length < 1) {
			state.poll.pollType
				? setState({ ...state, error: 'Option must be selected.' })
				: setState({ ...state, error: 'Atleast one must be selected.' });
		} else {
			setState({
				...state,
				error: '',
				submitting: true,
				hasParticipated: true,
			});
			const { submitPoll } = props;
			let selectedChoices = data.map((choice) => choice.id);
			submitPoll(
				state.poll.id,
				selectedChoices,
				slug,
				submitSuccess,
				submitError
			);
		}
	};

	return (
		<div>
			{!state.hasPoll && !state.loading && (
				<Alert variant="danger" className="text">
					No Poll Found
				</Alert>
			)}
			{state.loading && <small className="text-center">Loading Poll..</small>}
			{state.hasPoll && (
				<Form onSubmit={(e) => handleSubmit(e)}>
					<label>{state.poll.question}</label>
					{!state.poll.pollType &&
						checkbox.map((d, i) => (
							<Form.Check
								type="checkbox"
								label={d.choice}
								key={i}
								onChange={(event) => {
									let checked = event.target.checked;
									setCheckbox(
										checkbox.map((data) => {
											if (d.choice === data.choice) {
												data.select = checked;
											}
											return data;
										})
									);
								}}
								checked={d.select}
								disabled={state.hasParticipated}
							/>
						))}
					{state.poll.pollType &&
						radio.map((d, i) => (
							<Form.Check
								type="radio"
								label={d.choice}
								key={i}
								onChange={(event) => {
									let checked = event.target.checked;
									setRadio(
										radio.map((data) => {
											if (d.choice === data.choice) {
												data.select = checked;
											} else {
												data.select = false;
											}
											return data;
										})
									);
								}}
								checked={d.select}
								disabled={state.hasParticipated}
							/>
						))}
					{state.error && <div className="error">{state.error}</div>}
					<WithLoader
						submitting={state.submitting}
						style="float-right mb-2"
						disabledState={state.hasParticipated}
						value="Polling"
					/>
				</Form>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		isLoggedIn: (slug) => isLoggedIn(state, slug),
		schema: getSchemaName(state),
	};
};
const dispatchProps = {
	getSessionPoll,
	submitPoll,
};

export default connect(
	mapStateToProps,
	dispatchProps
)(withRouter(PollActivity));
